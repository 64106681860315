import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import OpcionInicio from "../OpcionInicio";
import BtnVolver from "../../common/BtnVolver";

const MenuReportes = () => {
    return (
        <Container className="mainSection">
            <Row className="auto mt-5">
                <Col
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <OpcionInicio
                        imagen={require("../../../img/MenuPrincipal/MenuPrincipal2/reportes-liquidaciones.png") }
                        texto={"Liquidaciones"}
                        ruta={"comisiones"}
                    />
                </Col>
                <Col
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <OpcionInicio
                        imagen={require("../../../img/MenuPrincipal/MenuPrincipal2/salon-sorteo.png")}
                        texto={"Salon Sorteo"}
                        ruta={"salon-sorteo"}
                    />
                </Col>
            </Row>
            <BtnVolver route="/" style={{ float: "right", marginTop: 150 }} />
        </Container>
    );
};

export default MenuReportes;

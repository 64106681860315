/* eslint-disable */
import React, { useMemo, useState, useEffect } from "react";
import { Col, Row, Card, Form, Modal, Container, Spinner } from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { useNavigate } from "react-router-dom";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import { IconButton } from "@mui/material";
import { AddBox } from "@mui/icons-material";
import Select from 'react-select'

let tablaLocalidades = [];
const FormItem = Form.Item;
const { Option } = Select;

export function ModalTitular(props) {
  const URL_TITULAR = window.API_ROUTES.TITULAR;
  const URL_LOCALIDAD = window.API_ROUTES.LOCALIDAD;
  const URL_CONDICIONFISCAL = window.API_ROUTES.CONDICIONFISCAL;
  const URL_DOMICILIOPARTICULAR = window.API_ROUTES.DOMICILIOPARTICULAR;
  const URL_CONDICIONFISCALTITULAR = window.API_ROUTES.CONDICIONFISCALTITULAR;
  const URL_PERSONA = window.API_ROUTES.PERSONA;
  const [archivo, setArchivo] = useState(null);
  const [fecha, setFecha] = useState({
    fechaMuestra: moment().format("DD/MM/YYYY"),
    fechaComparar: moment().format("YYYY-MM-DD"),
  });
  const [tipo, setTipo] = useState([]);
  const [mostrarError, setMostrarError] = useState(false);
  const [tipos, setTipos] = useState([]);
  const navigate = useNavigate();
  var currentTime = moment().utcOffset("-03:00").format("hh:mm");
  var currentDate = moment().utcOffset("-03:00").format("YYYY-MM-DD");
  const [tablaLocalidad, setTablaLocalidad] = useState([]);
  const [tablaCondFiscal, setTablaCondFiscal] = useState([]);
  const {
    register,
    formState: { errors },
    reset,
  } = useForm();
  const [editModal, setEditModal] = useState(false);

  //estos quedan
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [cuit, setCuit] = useState("");
  const [documento, setDocumento] = useState("");
  const [nroCuenta, setNroCuenta] = useState("");
  const [calle, setCalle] = useState("");
  const [casa, setCasa] = useState("");
  const [block, setBlock] = useState("");
  const [numero, setNumero] = useState();
  const [lote, setLote] = useState("");
  const [piso, setPiso] = useState("");
  const [manzana, setManzana] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [fechaDesde, setFechaDesde] = useState({
    fechaMuestra: moment().format('DD/MM/YYYY'),
    fechaComparar: moment().format('YYYY-MM-DD'),
  });
  const [fechaHasta, setFechaHasta] = useState({
    fechaMuestra: moment().format('DD/MM/YYYY'),
    fechaComparar: moment().format('YYYY-MM-DD'),
  });
  const [condicionNueva, setCondicionFiscalNueva] = useState("");
  const [condicionAntigua, setCondicionFiscalAntigua] = useState("");
  const [condicion, setCondicionFiscal] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");

  const [data, setData] = useState([]);

  const [count, setCount] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const requiredSymbol = props.titulo !== "Ver" ? <b style={{ color: "red" }}>*</b> : "";
  const [isLoading, setIsLoading] = useState(false)

  const columns = useMemo(() => [
    {
      header: "id",
      accessorKey: "id",
      size: 50,
    },
    {
      header: "Condicion Fiscal",
      accessorKey: "condicion",
      size: 50,
    },
    {
      header: "Condicion Fiscal",
      accessorKey: "condicion_fiscal",
      size: 50,
    },
    {
      header: "Fecha Desde",
      accessorKey: "fechaDesde",
      size: 50,
    },
    {
      header: "Fecha Hasta",
      accessorKey: "fechaHasta",
      size: 50,
    },
  ]);

  useEffect(() => {
    cargarCondicionFiscal();
    cargarLocalidades();
  }, []);

  useEffect(() => {
    if (props.show) {
      if (props.titulo !== "Nuevo") {
        cargarCondicionesTabla();
        setNombre(props.datosTitular.persona_detalle.nombre);
        setApellido(props.datosTitular.persona_detalle.apellido);
        setCuit(props.datosTitular.persona_detalle.cuit);
        setDocumento(props.datosTitular.persona_detalle.documento);
        setNroCuenta(props.datosTitular.persona_detalle.nro_cuenta);
        setEmail(props.datosTitular.persona_detalle.email);
        setTelefono(props.datosTitular.persona_detalle.telefono);
        setCalle(props.datosDomicilio.calle);
        setCasa(props.datosDomicilio.casa);
        setBlock(props.datosDomicilio.block);
        setNumero();
        setLote(props.datosDomicilio.lote);
        setPiso(props.datosDomicilio.piso);
        setManzana(props.datosDomicilio.manzana);
        setDepartamento(props.datosDomicilio.departamento);
        if (props.titulo === "Ver") {
          setLocalidad(props.datosDomicilio.localidad_nombre);
          setCondicionFiscal(props.datosCondicion.condicion_fiscal);
        } else {
          setLocalidad({ value: props.datosDomicilio.localidad, label: props.datosDomicilio.localidad_nombre });
          setCondicionFiscal(props.datosCondicion.condicionfiscal);
        }
      }
    }
  }, [props.show]);

  const cargarCondicionesTabla = () => {
    try {
      ConsultasAPI.ObtenerObjeto(
        URL_CONDICIONFISCALTITULAR + "busquedaList/",
        props.datosTitular.id
      ).then((response) => {
        let condiciones = response.data;
        let datos = [];
        if (condiciones) {
          condiciones.forEach((condicion) => {
            datos.push({
              id: condicion.id,
              condicion: condicion.condicionfiscal,
              condicion_fiscal: condicion.condicion_fiscal,
              fechaDesde: condicion.fecha_creacion.split(" ")[0],
              fechaHasta: condicion.fecha_hasta ? condicion.fecha_hasta : "-",
            });
          });
          setData(datos);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const cargarLocalidades = () => {
    let i = 0;
    ConsultasAPI.ListarObjetos(URL_LOCALIDAD + "listSinPag/").then(
      (response) => {
        let results = response.data.map((localidad) => ({ value: localidad.id, label: localidad.nombre }));
        setTablaLocalidad(results);
      }
    );
  };

  const cargarCondicionFiscal = () => {
    ConsultasAPI.ListarObjetos(URL_CONDICIONFISCAL).then((response) => {
      setTablaCondFiscal(response.data.results);
    });
  };

  // Guarda el archivo en la base de datos, pasa los datos al back
  const subirTitular = (event) => {
    Swal.fire({
      title: "¿Estás seguro de guardar el titular?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#008185",
      cancelButtonColor: "#EC1B23",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        let persona = {
          apellido: apellido,
          nombre: nombre,
          cuit: cuit,
          documento: documento,
          nro_cuenta: nroCuenta,
          email: email,
          telefono: telefono,
        };
        let condicionParticular = {
          fecha_creacion: fechaDesde.fechaComparar
            ? fechaDesde.fechaComparar
            : fecha.fechaComparar,
          condicionfiscal: condicion,
        };
        let domicilio = {
          calle: calle,
          numero: numero,
          manzana: manzana,
          casa: casa,
          lote: lote,
          block: block,
          piso: piso,
          departamento: departamento,
          localidad: localidad.value,
        };
        let data = { domicilio, condicionParticular, persona };
        try {
          ConsultasAPI.CrearObjeto(
            URL_TITULAR + "crearTitularCompleto/",
            data
          ).then((response) => {
            if (response.status === 201) {
              if (props.recargarTabla) props.recargarTabla();
              else if (props.nuevoTitular) props.nuevoTitular(response.data.id);
              setIsLoading(false);
              Swal.fire(
                "Crecion exitosa",
                "Se creo con Exito el titular",
                "success"
              );
              handleClose(event);
            }
          }).catch((error) => {
            setIsLoading(false);
            Swal.fire(
              "Crecion fallida",
              error.response.data.error.split('DETAIL:')[1],
              "error"
            );
          });

        } catch (error) {
          setIsLoading(false);
        }
      }
    });
  };

  const editarTitular = (event) => {
    event.preventDefault();
    Swal.fire({
      title: "¿Estás seguro de guardar los cambios en el titular?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#008185",
      cancelButtonColor: "#EC1B23",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      setIsLoading(true);
      if (result.isConfirmed) {

        let titular1 = {
          apellido:
            props.datosTitular.persona_detalle.apellido === apellido
              ? props.datosTitular.persona_detalle.apellido
              : apellido,
          nombre:
            props.datosTitular.persona_detalle.nombre === nombre
              ? props.datosTitular.persona_detalle.nombre
              : nombre,
          cuit:
            props.datosTitular.persona_detalle.cuit === cuit
              ? props.datosTitular.persona_detalle.cuit
              : cuit,
          documento:
            props.datosTitular.persona_detalle.documento === documento
              ? props.datosTitular.persona_detalle.documento
              : documento,
          nro_cuenta:
            props.datosTitular.persona_detalle.nro_cuenta === nroCuenta
              ? props.datosTitular.persona_detalle.nro_cuenta
              : nroCuenta,
          email:
            props.datosTitular.persona_detalle.email === email
              ? props.datosTitular.persona_detalle.email
              : email,
          telefono:
            props.datosTitular.persona_detalle.telefono === telefono
              ? props.datosTitular.persona_detalle.telefono
              : telefono,
        };
        let domicilio1 = {
          calle:
            props.datosDomicilio.calle === calle
              ? props.datosDomicilio.calle
              : calle,
          numero:
            props.datosDomicilio.numero === numero
              ? props.datosDomicilio.numero
              : numero,
          manzana:
            props.datosDomicilio.manzana === manzana
              ? props.datosDomicilio.manzana
              : manzana,
          casa:
            props.datosDomicilio.casa === casa ? props.datosDomicilio.casa : casa,
          lote:
            props.datosDomicilio.lote === lote
              ? props.datosDomicilio.calle
              : lote,
          block:
            props.datosDomicilio.block === block
              ? props.datosDomicilio.block
              : block,
          piso:
            props.datosDomicilio.piso === piso ? props.datosDomicilio.piso : piso,
          departamento:
            props.datosDomicilio.departamento === departamento
              ? props.datosDomicilio.departamento
              : departamento,
          localidad:
            props.datosDomicilio.localidad === localidad.value
              ? props.datosDomicilio.localidad
              : localidad.value,
        };
        try {
          await ConsultasAPI.ModificarObjeto(
            URL_PERSONA,
            props.datosTitular.persona,
            titular1
          );
          await ConsultasAPI.ModificarObjeto(
            URL_DOMICILIOPARTICULAR,
            props.datosDomicilio.id,
            domicilio1
          );
          setIsLoading(false)
          Swal.fire(
            "Edicion exitosa",
            "Se edito con Exito el titular",
            "success"
          );
          handleClose(event);
        } catch (error) {
          setIsLoading(false)
          Swal.fire(
            "Edicion fallida",
            error,
            "error"
          );
        }
      }
    });
  };

  const OpenEditModal = () => {
    let condic = data;
    condic.forEach((condicion) => {
      if (condicion.fechaHasta === "-") {
        setCondicionFiscalAntigua(condicion.condicion_fiscal);
      }
    });
    setEditModal(true);
  };

  const handleCloseEdit = () => {
    setEditModal(false);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setNombre("");
    setApellido("");
    setCuit("");
    setDocumento("");
    setNroCuenta("");
    setEmail("");
    setTelefono("");
    setLocalidad("");
    setCalle("");
    setNumero();
    setManzana("");
    setCasa("");
    setLote("");
    setBlock("");
    setPiso("");
    setDepartamento("");
    setCondicionFiscal("");
    setData([]);
    // props.recargarTabla()
    props.onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.titulo === "Nuevo") {
      subirTitular(event);
    }
    if (props.titulo === "Editar") {
      editarTitular(event);
    }
  };

  const handleSubmitEdit = (event) => {
    event.preventDefault();

    Swal.fire({
      title: "¿Estás seguro de crear una nueva condicion fiscal?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#008185",
      cancelButtonColor: "#EC1B23",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      event.preventDefault();
      try {
        let condic = data;
        condic.forEach((condicion) => {
          if (condicion.fechaHasta === "-") {
            try {
              let condicion1 = {
                condicionfiscal: condicion.condicion,
                fecha_hasta: fechaHasta.fechaComparar,
              };
              ConsultasAPI.ModificarObjeto(
                URL_CONDICIONFISCALTITULAR,
                condicion.id,
                condicion1
              );
            } catch (error) {
              console.error(error);
            }
          }
        });
        let condicionParticular = {
          condicionfiscal: condicion,
          titular: props.datosTitular.id,
          fecha_creacion: fechaDesde.fechaComparar
        };
        ConsultasAPI.CrearObjeto(
          URL_CONDICIONFISCALTITULAR,
          condicionParticular
        ).then((response) => {
          if (response.status === 201) {
            cargarCondicionesTabla();
            Swal.fire(
              "Crecion exitosa",
              "Se creo con Exito la nueva condicion fiscal",
              "success"
            );
            setFechaDesde({
              fechaMuestra: moment().format("DD/MM/YYYY"),
              fechaComparar: moment().format("YYYY-MM-DD"),
            });
            setFechaHasta({
              fechaMuestra: moment().format("DD/MM/YYYY"),
              fechaComparar: moment().format("YYYY-MM-DD"),
            });
            setFecha({
              fechaMuestra: moment().format("DD/MM/YYYY"),
              fechaComparar: moment().format("YYYY-MM-DD"),
            });
            setCondicionFiscalAntigua("");
            handleCloseEdit();
          }
        });
      } catch (error) { }
    });
  };

  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter

      // Encuentra el índice del campo actual dentro del formulario
      const formControls = event.target.form.elements;
      const currentIndex = Array.from(formControls).indexOf(event.target);

      // Encuentra el siguiente campo y enfócalo
      const nextControl = formControls[currentIndex + 1];
      if (nextControl) {
        nextControl.focus();
      }
    }
  };


  return (
    <Container>
      <Modal show={props.show} size="lg">
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>{props.titulo} Titular</Modal.Title>
        </Modal.Header>
        <Form onKeyDown={handleFormKeyDown} style={{ width: "100%" }}>
          <Modal.Body style={{ width: "100%" }}>
            <Card className="m-3">
              <CardHeader>
                <Card.Title>Datos Personales</Card.Title>
              </CardHeader>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>
                      Apellidos {requiredSymbol}
                    </Form.Label>
                    <Form.Control
                      readOnly={props.titulo === "Ver" ? true : false}
                      type="text"
                      placeholder={"Ingrese los apellidos"}
                      value={apellido}
                      onChange={(event) => {
                        if(event.target.value.trim() !== ''){
                          setApellido(event.target.value);
                        }else{
                          setApellido('');
                        }
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Nombres {requiredSymbol}</Form.Label>
                    <Form.Control
                      readOnly={props.titulo === "Ver" ? true : false}
                      type="text"
                      placeholder={"Ingrese los nombres"}
                      value={nombre}
                      onChange={(event) => {
                        if(event.target.value.trim() !== ''){
                          setNombre(event.target.value);
                        }else{
                          setNombre('');
                        }
                      }}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>CUIT {requiredSymbol}</Form.Label>
                    <Form.Control
                      type="text"
                      inputMode="numeric"
                      readOnly={props.titulo === "Ver" ? true : false}
                      placeholder={"Ingrese CUIT"}
                      value={cuit}
                      onChange={(event) => setCuit(event.target.value)}
                      onKeyPress={(event) => {
                        // Solo permitir números
                        const regex = /[0-9]/;
                        const key = String.fromCharCode(event.charCode);
                        if (!regex.test(key)) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Documento {requiredSymbol}</Form.Label>
                    <Form.Control
                      type="text"
                      inputMode="numeric"
                      readOnly={props.titulo === "Ver" ? true : false}
                      placeholder={"Ingrese documento"}
                      value={documento}
                      onKeyPress={(event) => {
                        // Solo permitir números
                        const regex = /[0-9]/;
                        const key = String.fromCharCode(event.charCode);
                        if (!regex.test(key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => setDocumento(event.target.value)}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Email {requiredSymbol}</Form.Label>
                    <Form.Control
                      type="email"
                      readOnly={props.titulo === "Ver" ? true : false}
                      placeholder={"Ingrese Email"}
                      value={email}
                      onChange={(event) => {
                        if(event.target.value.trim() !== ''){
                          setEmail(event.target.value);
                        }else{
                          setEmail('');
                        }
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Teléfono</Form.Label>
                    <Form.Control
                      type="text"
                      inputMode="numeric"
                      readOnly={props.titulo === "Ver" ? true : false}
                      placeholder={"Ingrese el telefono"}
                      value={telefono}
                      onChange={(event) => setTelefono(event.target.value)}
                      onKeyPress={(event) => {
                        // Solo permitir números
                        const regex = /[0-9]/;
                        const key = String.fromCharCode(event.charCode);
                        if (!regex.test(key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Número de Cuenta</Form.Label>
                    <Form.Control
                      type="text"
                      inputMode="numeric"
                      readOnly={props.titulo === "Ver" ? true : false}
                      placeholder={"Ingrese Número de Cuenta"}
                      value={nroCuenta}
                      onKeyPress={(event) => {
                        // Solo permitir números
                        const regex = /[0-9]/;
                        const key = String.fromCharCode(event.charCode);
                        if (!regex.test(key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => setNroCuenta(event.target.value)}
                    />
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>
            <Card className="m-3">
              <CardHeader>
                <Card.Title>Datos de Domicilio</Card.Title>
              </CardHeader>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Municipio/Comuna {requiredSymbol}</Form.Label>
                    {props.titulo !== "Ver" ?
                      <Select
                        value={localidad}
                        onChange={(option) => { setLocalidad(option) }}
                        options={tablaLocalidad}
                        placeholder="Seleccionar Localidad"
                        required
                      />
                      :
                      <Form.Control
                        type="text"
                        readOnly={true}
                        value={localidad}
                      />
                    }
                  </Form.Group>
                  <Form.Group as={Col} md="8">
                    <Form.Label>Dirección {requiredSymbol}</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={props.titulo === "Ver" ? true : false}
                      value={calle}
                      onChange={(event) => {
                        if(event.target.value.trim() !== ''){
                          setCalle(event.target.value);
                        }else{
                          setCalle('')
                        }
                      }}
                      required
                      placeholder={"Ingresar dirección"}
                    />
                  </Form.Group>
                  {/* <Form.Group as={Col} md="4">
                    <Form.Label>Número {requiredSymbol}</Form.Label>
                    <Form.Control
                      type="text"
                      inputMode="numeric"
                      readOnly={props.titulo === "Ver" ? true : false}
                      value={numero}
                      onChange={(event) => setNumero(event.target.value)}
                      required
                      onKeyPress={(event) => {
                        // Solo permitir números
                        const regex = /[0-9]/;
                        const key = String.fromCharCode(event.charCode);
                        if (!regex.test(key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder={"Ingresar N° de calle"}
                      maxLength={5}
                    />
                  </Form.Group> */}
                </Row>
                {/* <Row className="mb-3">
                  <Form.Group as={Col} md="2">
                    <Form.Label>Manzana</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={props.titulo === "Ver" ? true : false}
                      value={manzana}
                      onChange={(event) => setManzana(event.target.value)}
                      placeholder={"N° Mzn"}
                      maxLength={3}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Casa</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={props.titulo === "Ver" ? true : false}
                      value={casa}
                      onChange={(event) => setCasa(event.target.value)}
                      placeholder={"N° casa"}
                      maxLength={3}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Lote</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={props.titulo === "Ver" ? true : false}
                      value={lote}
                      onChange={(event) => setLote(event.target.value)}
                      placeholder={"N° lote"}
                      maxLength={3}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Block</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={props.titulo === "Ver" ? true : false}
                      value={block}
                      onChange={(event) => setBlock(event.target.value)}
                      placeholder={"N° block"}
                      maxLength={3}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Piso</Form.Label>
                    <Form.Control
                      type="text"
                      inputMode="numeric"
                      readOnly={props.titulo === "Ver" ? true : false}
                      value={piso}
                      onChange={(event) => setPiso(event.target.value)}
                      placeholder={"N° piso"}
                      maxLength={3}
                      onKeyPress={(event) => {
                        // Solo permitir números
                        const regex = /[0-9]/;
                        const key = String.fromCharCode(event.charCode);
                        if (!regex.test(key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Departamento</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={props.titulo === "Ver" ? true : false}
                      value={departamento}
                      onChange={(event) => setDepartamento(event.target.value)}
                      placeholder={"Dpto"}
                      maxLength={3}
                    />
                  </Form.Group>
                </Row> */}
              </Card.Body>
            </Card>
            <Card className="m-3">
              <CardHeader>
                <Card.Title>Condición Fiscal</Card.Title>
              </CardHeader>
              <Card.Body>
                {props.titulo === "Nuevo" && (
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6">
                      <Form.Label>Condición Fiscal {requiredSymbol}</Form.Label>
                      <Form.Select
                        readOnly={props.titulo === "Ver" ? true : false}
                        value={condicion}
                        onChange={(event) =>
                          setCondicionFiscal(event.target.value)
                        }
                        required
                      >
                        <option value="" disabled hidden>
                          Seleccionar Condicion
                        </option>

                        {tablaCondFiscal.map((condicion) => (
                          <option key={condicion.id} value={condicion.id}>
                            {condicion.condicion}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Desde {requiredSymbol}</Form.Label>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          readOnly: true,
                          placeholder: fecha.fechaMuestra,
                        }}
                        required
                        value={fecha.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format("DD/MM/YYYY");
                          const fechaComparar = momentDate.format("YYYY-MM-DD");
                          setFecha({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                          setFechaDesde({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    </Form.Group>
                  </Row>
                )}
                {props.titulo === "Editar" && (
                  <IconButton
                    style={{ alignContent: "center", margin: 15, float: "right" }}
                    onClick={() => {
                      OpenEditModal();
                    }}
                  >
                    <AddBox />
                  </IconButton>
                )}
                {props.titulo !== "Nuevo" && (
                  <MaterialReactTable
                    className="w-100"
                    columns={columns}
                    data={data}
                    enableTopToolbar={false}
                    initialState={{
                      columnVisibility: { id: false, condicion: false },
                      showColumnFilters: false,
                    }} //hide firstName column by default
                    editingMode="modal" //default
                    enableRowSelection={false} //enable some features
                    enableColumnOrdering={false}
                    enableHiding={false}
                    enableColumnActions={false}
                    enableSorting={false}
                    enableFilters={false}
                    displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }} //change width of actions column to 300px
                    positionActionsColumn="last"
                    manualPagination
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [10],
                    }}
                    enablePagination={false}
                    localization={MRT_Localization_ES}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    enableGlobalFilter={false} //turn off a feature
                  />
                )}
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn botonCPA m-3" onClick={handleClose}>
              {props.titulo === "Ver" ? "Volver" : "Cancelar"}
            </button>
            {props.titulo === "Ver" ? null : (
              <button className="btn botonCPA m-3" onClick={handleSubmit}>
                Guardar
              </button>
            )}
          </Modal.Footer>
        </Form>
        <Modal show={editModal}>
          <Modal.Header closeButton onClick={handleCloseEdit}>
            <Modal.Title>Nueva Condicion Fiscal</Modal.Title>
          </Modal.Header>
          <Form style={{ width: "100%" }}>
            <Modal.Body style={{ width: "100%" }}>
              <Card className="m-3">
                <Card.Body>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6">
                      <Form.Label>Condicion Fiscal Anterior</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly={true}
                        value={condicionAntigua}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Hasta {requiredSymbol}</Form.Label>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          readOnly: true,
                          placeholder: fecha.fechaMuestra,
                        }}
                        required
                        value={fechaHasta.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format("DD/MM/YYYY");
                          const fechaComparar = momentDate.format("YYYY-MM-DD");
                          setFechaHasta({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6">
                      <Form.Label>Condicion Fiscal Nueva {requiredSymbol}</Form.Label>
                      <Form.Select
                        readOnly={props.titulo === "Ver" ? true : false}
                        value={condicion}
                        onChange={(event) =>
                          setCondicionFiscal(event.target.value)
                        }
                        required
                      >
                        <option value="" disabled hidden>
                          Seleccionar Condicion
                        </option>

                        {tablaCondFiscal.map((condicion) => (
                          <option key={condicion.id} value={condicion.id}>
                            {condicion.condicion}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Desde {requiredSymbol}</Form.Label>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{
                          readOnly: true,
                          placeholder: fecha.fechaMuestra,
                        }}
                        required
                        value={fechaDesde.fechaMuestra}
                        onChange={(momentDate) => {
                          const fechaMuestra = momentDate.format("DD/MM/YYYY");
                          const fechaComparar = momentDate.format("YYYY-MM-DD");
                          setFechaDesde({
                            fechaMuestra: fechaMuestra,
                            fechaComparar: fechaComparar,
                          });
                        }}
                      />
                    </Form.Group>
                  </Row>
                </Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn botonCPA m-3" onClick={handleSubmitEdit}>
                Guardar{" "}
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Modal>
      <Modal
        centered
        show={isLoading}
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          <p>Subiendo cambios...</p>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

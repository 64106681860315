import React, { useState, useEffect, useRef, useMemo } from "react";
import { Container, Col, Row, Card, Form, Spinner, Modal, Button, Dropdown } from "react-bootstrap";
import Datetime from 'react-datetime';
import ConsultasAPI from "../../../shared/helpers/consultasAPI.jsx";
import { FaEraser, FaFileDownload, FaClipboardCheck } from "react-icons/fa";
import BtnVolver from "../../common/BtnVolver.jsx";
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import MaterialReactTable from 'material-react-table';
import { darken } from '@mui/material';
import moment from "moment"
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Link } from 'react-router-dom';

const SalonSorteo = () => {

    const URL_CONJUNTOBOLILLEROS = window.API_ROUTES.CONJUNTOBOLILLEROS
    const [fechaDesde, setFechaDesde] = useState({
    });
    const fechaDesdeRef = useRef(null);
    const [fechaHasta, setFechaHasta] = useState({
    });
    const fechaHastaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [data, setData] = useState([])
    const [numeroSorteo, setNumeroSorteo] = useState('')
    const [incidente, setIncidente] = useState('')
    const [count, setCount] = useState([])
    const columns = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 10,
        },
        {
            header: "N° Sorteo",
            accessorKey: "numero_sorteo",
            size: 10,
        },
        {
            header: "Fecha de Sorteo",
            accessorKey: "fecha_sorteo",
            size: 10,
        },
        {
            header: "Incidente",
            accessorKey: "incidente",
            size: 10,
        },
        {
            header: "Usuario",
            accessorKey: "usuario",
            size: 10,
        },
    ]);
    useEffect(() => {
        cargarSorteos()
    }, [fechaDesde, fechaHasta, numeroSorteo, incidente]);

    const cargarSorteos = async () => {
        const filters = {
            fecha_desde: fechaDesde.fechaComparar,
            fecha_hasta: fechaHasta.fechaComparar,
            numero_sorteo: numeroSorteo,
            incidente: incidente,
            paginado: 'true',
        };
        const conjuntos = await ConsultasAPI.ListarObjetos(URL_CONJUNTOBOLILLEROS, pagination.pageIndex, pagination.pageSize, filters)
        const data = []
        conjuntos.data.results.forEach(conjunto => {
            const bolilleros = [{
                incidente: <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Color de Bolilla y Posición</span>,
                numero_sorteo: <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Numero de Bolillero</span>,
            }]
            conjunto.bolilleros.forEach(bolillero => {
                bolilleros.push({
                    id: bolillero.id,
                    incidente: bolillero.bolilla_detalle,
                    numero_sorteo: bolillero.bolillero_detalle,
                })
            });
            data.push({
                id: conjunto.id,
                numero_sorteo: conjunto.numero_sorteo,
                fecha_sorteo: moment(conjunto.fecha_creacion, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY'),
                incidente: conjunto.incidente,
                usuario: conjunto.usuario,
                subRows: bolilleros,
            })
        });
        setCount(conjuntos.data.count)
        setData(data)
    }

    const handleClear = () => {
        fechaDesdeRef.current.setState({ inputValue: "" });
        fechaHastaRef.current.setState({ inputValue: "" });
        setFechaDesde({
            fechaMuestra: null,
            fechaHasta: null
        });
        setFechaHasta({
            fechaMuestra: null,
            fechaHasta: null
        });
        setNumeroSorteo('');
        setIncidente('');
    }

    const handleClickPDF = async () => {
        setIsLoading(true)
        const filters = {
            fecha_desde: fechaDesde.fechaComparar,
            fecha_hasta: fechaHasta.fechaComparar,
            numero_sorteo: numeroSorteo,
            incidente: incidente,
            paginado: 'false',
        };
        const conjuntos = await ConsultasAPI.ListarObjetos(URL_CONJUNTOBOLILLEROS, pagination.pageIndex, pagination.pageSize, filters)
        const data_pdf = []
        conjuntos.data.forEach(conjunto => {
            const bolilleros_pdf = []
            conjunto.bolilleros.forEach(bolillero => {
                bolilleros_pdf.push({
                    id: bolillero.id,
                    incidente: bolillero.bolilla_detalle,
                    numero_sorteo: bolillero.bolillero_detalle,
                })
            });
            data_pdf.push({
                'Numero de Sorteo': conjunto.numero_sorteo,
                'Fecha de Sorteo': moment(conjunto.fecha_creacion, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY'),
                'Incidente': conjunto.incidente,
                'Cargado Por': conjunto.usuario,
                'Numero de Bolillero': bolilleros_pdf,
                'Color y Posicion de Bolilla': ''
            })
        });
        convertToPDF(data_pdf)
    }

    function convertToPDF(agencias) {
        // setLoading(true);
        pdfMake.vfs = pdfFonts.pdfMake.vfs;


        const headers = Object.keys(agencias[0]); // Obtener las propiedades del primer objeto del JSON como encabezados de la tabla

        const body = [];
        agencias.forEach((data) => {
            if (data['Numero de Bolillero'] && data['Numero de Bolillero'].length > 0) {
                body.push([
                    data['Numero de Sorteo'],
                    data['Fecha de Sorteo'],
                    data['Incidente'],
                    data['Cargado Por'],
                    '',
                    '',
                ]);
                data['Numero de Bolillero'].forEach((subRow) => {
                    body.push([
                        '',
                        '',
                        '',
                        '',
                        subRow.numero_sorteo,
                        subRow.incidente,
                    ]);
                });
            }
        });// Obtener los valores de cada objeto del JSON

        const table = {
            headerRows: 1,
            widths: headers.map(() => 'auto'),
            body: [
                headers, // Encabezados de la tabla
                ...body, // Datos del JSON
            ],
        };

        const documentDefinition = {
            pageOrientation: 'landscape',
            content: [
                { text: 'Registro de Incidentes', style: 'header' },
                { text: 'Salon de Sorteo:', style: 'subheader' },
                {
                    table: table,
                },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 5],
                },
            },
        };
        agencias = [];
        setIsLoading(false)
        pdfMake
            .createPdf(documentDefinition)
            .download('incidentes.pdf');
    }

    return (
        <Container fluid className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Salon Sorteo</h1>
                </Col>
            </Row>
            <Card className="m-3">

                <Card.Body>
                    <Row
                        className="mb-3"
                        style={{
                            marginTop: "2%",
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Col md={10}>
                            <Row
                                className="mb-3"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <Col md={3}>
                                    <Form.Label>Fecha Sorteo Desde</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        style={{ width: "100%", height: "32px" }}
                                        dateFormat="DD/MM/YYYY"
                                        inputProps={{
                                            readOnly: true,
                                            placeholder: "Elegir Fecha",
                                        }}
                                        ref={fechaDesdeRef}
                                        value={fechaDesde.fechaMuestra}
                                        onChange={(momentDate) => setFechaDesde({
                                            fechaMuestra: momentDate.format('DD/MM/YYYY'),
                                            fechaComparar: momentDate.format('YYYY-MM-DD')
                                        })}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Fecha Sorteo Hasta</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        style={{ width: "100%", height: "32px" }}
                                        dateFormat="DD/MM/YYYY"
                                        inputProps={{
                                            readOnly: true,
                                            placeholder: "Elegir Fecha",
                                        }}
                                        ref={fechaHastaRef}
                                        value={fechaHasta.fechaMuestra}
                                        onChange={(momentDate) => setFechaHasta({
                                            fechaMuestra: momentDate.format('DD/MM/YYYY'),
                                            fechaComparar: momentDate.format('YYYY-MM-DD')
                                        })}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Numero de Sorteo</Form.Label>
                                    <Form.Control
                                        value={numeroSorteo}
                                        onChange={(event) => { setNumeroSorteo(event.target.value) }}
                                        placeholder="Ingrese Numero de Sorteo"
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Incidente</Form.Label>
                                    <Form.Select
                                        value={incidente}
                                        onChange={(event) => { setIncidente(event.target.value) }}
                                    >
                                        <option
                                            key={0}
                                            value={''}
                                        >
                                            Elegir Opcion
                                        </option>
                                        <option
                                            key={1}
                                            value={true}
                                        >
                                            Si
                                        </option>
                                        <option
                                            key={2}
                                            value={false}
                                        >
                                            No
                                        </option>
                                    </Form.Select>

                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                            <Button
                                className="btn botonCPA me-3"
                                onClick={handleClear}
                                style={{ margin: '15px', width: 'fit-content', height: 'fit-content' }}
                            >
                                <FaEraser className="iconAntD" />
                                Limpiar Filtro
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
                <MaterialReactTable
                    className="w-100"
                    columns={columns}
                    data={data}
                    muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: "0",
                            border: "1px dashed #e0e0e0",
                        },
                    }}
                    enableExpanding={true}
                    getSubRows={(originalRow) => originalRow.subRows}
                    muiTableBodyProps={{
                        sx: (theme) => ({
                            "& tr:nth-of-type(odd)": {
                                backgroundColor: darken(theme.palette.background.default, 0.1),
                            },
                            fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                        }),
                    }}
                    initialState={{
                        columnVisibility: { id: false },
                        showColumnFilters: true,
                    }} //hide firstName column by default
                    editingMode="modal" //default
                    enableRowSelection={false} //enable some features
                    enableColumnOrdering={false}
                    enableHiding={false}
                    enableColumnActions={false}
                    enableSorting={false}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    manualPagination
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [10],
                    }}
                    enablePagination={true}
                    localization={MRT_Localization_ES}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    enableGlobalFilter={false} //turn off a feature
                    manualFiltering //turn off client-side filtering
                    // onColumnFiltersChange={(value) => {
                    //     // setColumnFilters(value);
                    // }} //hoist internal columnFilters state to your state
                    state={{
                        // columnFilters,
                        pagination,
                    }}
                />

            </Card>
            <Row className="text-center">
                <section className="d-flex justify-content-end my-3">
                    {
                        count > 0 ?
                            <Link
                                className="btn botonCPA-PDF me-3"
                                onClick={handleClickPDF}
                            >
                                <FaFileDownload className="iconAntD" />
                                Descargar PDF
                            </Link>
                            :
                            null
                    }
                    <BtnVolver />
                </section>
            </Row>
            <Modal
                centered
                show={isLoading}
            >
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Cargando...</p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};
export default SalonSorteo;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card, Button, Form, Modal, Spinner } from "react-bootstrap";
import Datetime from "react-datetime";
// import { useNavigate } from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import { FaEraser } from "react-icons/fa";

import MaterialReactTable from "material-react-table";
import {
  BsCheckCircleFill,
  BsFillXSquareFill,
  BsDashCircleFill,
} from "react-icons/bs";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import moment from "moment";
import ExportarRendiciones from "./ExportarRendiciones";
import BtnVolver from "../../../common/BtnVolver";
import ConsultasAPI from "../../../../shared/helpers/consultasAPI";
import { AiOutlineUpload, AiOutlineDownload } from "react-icons/ai";
import { Box } from "@mui/material";
import Parser from "../../../common/Parser";
import Notificaciones from "../../../../shared/helpers/notificacionesToast";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";
import { ModalNuevaJugada } from "./ModalNuevaJugada";
import { FaPlusCircle } from "react-icons/fa";


const Rendiciones = (props) => {
  const rolUser = props.rolUsuario;

  // const navigate = useNavigate();

  const [agencias, setAgencias] = useState([]);
  const [localidades, setLocalidades] = useState([]);

  const [fechaDesde, setFechaDesde] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [fechaHasta, setFechaHasta] = useState(moment().format("YYYY-MM-DD"));
  const [agencia, setAgencia] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [localidad, setLocalidad] = useState();
  const [rendicionesTabla, setRendicionesTabla] = useState([]);
  const [recalcComision, setRecalcComision] = useState(false);
  // const [rendicionesFecha, setRendicionesFecha] = useState([]);
  const [count, setCount] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [openExport, setOpenExport] = useState(false);

  const URL_RENDICION_AGENCIA = window.API_ROUTES.RENDICIONAGENCIA;
  const URL_AGENCIAS = window.API_ROUTES.AGENCIAS;
  const URL_LOCALIDAD = window.API_ROUTES.LOCALIDAD;


  const [showModalNuevaJugada, setShowModalNuevaJugada] = useState(false);
  const [tituloModal, setTituloModal] = useState("");

  const handleCloseModalNuevaJugada = () => {
    setShowModalNuevaJugada(false);
  };

  const handleOpenModalNuevaJugada = () => {
    setTituloModal("Nuevo");
    setShowModalNuevaJugada(true);
  };



  const columns = [
    {
      header: "id",
      accessorKey: "id",
      size: 20,
    },
    {
      header: "Agencia",
      accessorKey: "agencia.numero_agencia",
      size: 20,
    },
    {
      header: "Subida por",
      accessorKey: "creado_por.email",
      size: 20,
    },
    {
      header: "Hora de Registro",
      accessorKey: "fecha_creacion",
      size: 50,
    },
    // {
    //   header: "Jurisdicción",
    //   accessorKey: "agencia.ultimo_domicilio.jurisdiccion_nombre",
    //   size: 50,
    // },
    {
      header: "Total Ingreso",
      accessorKey: "ingreso_total",
      size: 30,
      Cell: ({ renderedCellValue }) => (
        <span>{Parser.currency(renderedCellValue)}</span>
      ),
    },
    {
      header: "Total Egreso",
      accessorKey: "egreso_total",
      size: 30,
      Cell: ({ renderedCellValue }) => (
        <span>{Parser.currency(renderedCellValue)}</span>
      ),
    },
    {
      header: "Total a Rendir",
      accessorKey: "total_a_rendir",
      size: 30,
      Cell: ({ renderedCellValue }) => (
        <span>{Parser.currency(renderedCellValue)}</span>
      ),
    },
    {
      header: "Fecha Rendicion",
      accessorKey: "fecha_inicio",
      size: 30,
    },
    {
      header: "Estado",
      accessorKey: "estado_rendicion",
      size: 30,
    },
    {
      header: "Pagada por",
      accessorKey: "cajero_hora",
      size: 30,
      // Cell: ({ renderedCellValue, row }) => (
      //   <span>{renderedCellValue?? "-"}</span>
      // ),
    },
  ];

  var valid = function (current) {
    const today = moment();
    const isSunday = current.day() === 0;
    return current.isBefore(today) && !isSunday;
  };

  useEffect(() => {


    ConsultasAPI.ListarObjetos(URL_AGENCIAS + "ListaFiltrar/").then(
      (response) => {
        setAgencias(response.data);
      }
    );
    ConsultasAPI.ListarObjetos(URL_LOCALIDAD + "listSinPag/").then(
      (response) => {
        setLocalidades(response.data);
      }
    );

  }, []);

  useEffect(() => {
    const filters = {
      agencia: agencia && agencia !== "-" ? agencia : "",
      tipoSucursal: AuthenticationHelper.getRol() === "SUCURSAL-CONCEPCION" ? 3 : "",
      fechaDesde,
      fechaHasta,
      localidad: localidad && localidad !== "-" ? localidad : "",
    };
    ConsultasAPI.ListarObjetos(
      URL_RENDICION_AGENCIA,
      pagination.pageIndex,
      pagination.pageSize,
      filters
    ).then((response) => {
      setCount(response.data.count);
      setRendicionesTabla(response.data.results);
    });

  }, [
    fechaDesde,
    fechaHasta,
    pagination.pageIndex,
    pagination.pageSize,
    agencia,
    localidad,
    isLoading,
  ]);

  useEffect(() => {
    actualizar();
  }, [
    fechaDesde,
    fechaHasta,
  ]);

  const actualizar = async () => {
    setIsLoading(true)
    const data = {
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta,
    };
    const actualizacion = await ConsultasAPI.CrearObjeto(
      URL_RENDICION_AGENCIA + 'actualizarStubs/',
      data
    )
    if (actualizacion.status === 200) {
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  };

  const handleClickClear = () => {
    setFechaDesde(moment().subtract(2, "days").format("YYYY-MM-DD"));
    setFechaHasta(moment().format("YYYY-MM-DD"));
    setAgencia("-");
    setLocalidad("-");
  };
  const cargarRendiciones = () => {
    window.location.href += "/cargar-rendiciones";
  };

  // const registrarRendiciones = () => {
  //   window.location.href += "/registrar-rendiciones";
  // };

  const calcularVencimiento = (fecha) => {
    const fechaRendicion = moment(fecha, "DD/MM/YYYY");
    const fechaActual = moment()

    if (fechaActual.diff(fechaRendicion, "days") >= 2) {
      return true;
    } else {
      return false;
    }
  };

  const cambiarEstado = (id_rendicion) => {
    let estado_rendicion = "";
    const rendicion = rendicionesTabla.find(
      (rendicion) => rendicion.id === id_rendicion
    );
    if (rendicion.estado_rendicion === "Pendiente") {
      estado_rendicion = "Vencida";
    };

    setRecalcComision(true);
    ConsultasAPI.ModificarObjeto(URL_RENDICION_AGENCIA, id_rendicion, {
      estado_rendicion: estado_rendicion,
    })
      .then((response) => {
        rendicion.estado_rendicion = estado_rendicion;
        rendicion.actualizado_por = response.data.actualizado_por;
        setRendicionesTabla([...rendicionesTabla]);
        setRecalcComision(false);
      })
      .catch((error) => {
        Notificaciones.notificacion(error.response.data.error);
        setRecalcComision(false);
      });
  };

  return (
    <Container className="mainSection">
      <Row>
        <Col>
          <h1 className="py-4 fw-bold">Rendiciones</h1>
        </Col>
        <Col className="d-flex justify-content-end my-3 ">

          {rolUser === "ADMINISTRADOR" ||
            rolUser === "DOCUMENTACION" ||
            rolUser === "CONTADURIA" ||
            rolUser === "GERENCIA-QUINIELA" ? (



            <Button className="btn botonCPA m-3" onClick={handleOpenModalNuevaJugada}>
              <FaPlusCircle className="iconAntD" />
              AGREGAR
            </Button>
          ) : null}


          {rolUser === "ADMINISTRADOR" ||
            rolUser === "DOCUMENTACION" ||
            rolUser === "CONTADURIA" ||
            rolUser === "GERENCIA-QUINIELA" ? (



            <Button className="btn botonCPA m-3" onClick={cargarRendiciones}>
              <AiOutlineUpload className="iconAntD" />
              SUBIR ARCHIVO
            </Button>
          ) : null}
          {/* <Button className="btn botonCPA m-3" onClick={registrarRendiciones}>
            <AiFillEdit  /> REGISTRAR
            RENDICIONES
          </Button> */}
        </Col>
      </Row>
      {/* <Row> */}
      <Card>
        <Card.Body>
          <Row>
            <Form.Group as={Col} md="2">
              <Form.Label>Fecha Desde</Form.Label>
              <Datetime
                isValidDate={valid}
                closeOnSelect
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
                inputProps={{
                  readOnly: true,
                  placeholder: fechaDesde,
                }}
                value={fechaDesde}
                onChange={setFechaDesde}
              />
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label>Fecha Hasta</Form.Label>
              <Datetime
                isValidDate={valid}
                closeOnSelect
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
                inputProps={{
                  readOnly: true,
                  placeholder: fechaHasta,
                }}
                value={fechaHasta}
                onChange={setFechaHasta}
              />
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label>Agencia</Form.Label>
              <Form.Select
                // style={{ width: "18%", height: "32px" }}

                value={agencia}
                onChange={(event) => setAgencia(event.target.value)}
                disabled={agencias.length === 0}
              >
                <option hidden>-</option>
                {agencias
                  .filter((agencia) => agencia.numero_subagencia === 0)
                  .map((agencia) => (
                    <option key={agencia.id} value={agencia.id}>
                      {agencia.numero_agencia}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label>Municipio/Comuna</Form.Label>
              <Form.Select
                // style={{ width: "14%", height: "37px" }}
                value={localidad}
                onChange={(event) => setLocalidad(event.target.value)}
                disabled={localidades.length === 0}
              >
                <option hidden>-</option>
                {localidades.map((localidad) => (
                  <option key={localidad.id} value={localidad.id}>
                    {localidad.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Button
                className="btn botonCPA me-3"
                onClick={handleClickClear}
                style={{ alignContent: "center", margin: 15, float: "right" }}
              >
                <FaEraser className="iconAntD" />
                Limpiar Filtro
              </Button>
            </Form.Group>
          </Row>
          {/* <Row>
            <section className=" d-flex justify-content-center my-3">
              <Button
                className="btn botonCPA m-3"
                onClick={() => {
                  // setRendicionesTabla(rendicionesFecha);
                }}
              >
                Limpiar Filtro
              </Button>
            </section>
          </Row> */}
        </Card.Body>
        {/* </Row> */}
        <MaterialReactTable
          className="w-100"
          columns={columns}
          data={rendicionesTabla}
          initialState={{ columnVisibility: { id: false }, density: 'compact' }}
          enableRowSelection={false}
          enableColumnOrdering={false}
          enableSorting={false}
          enableGlobalFilter={false}
          localization={MRT_Localization_ES}
          manualPagination
          enablePagination={true}
          rowCount={count}
          onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
          // state={{ pagination }} //pass the pagination state to the table
          state={{
            pagination,
          }}
          enableRowActions={
            rolUser === "ADMINISTRADOR" ||
            rolUser === "DOCUMENTACION" ||
            rolUser === "CONTADURIA" ||
            rolUser === "GERENCIA-QUINIELA"
          }
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <Box className="d-flex">
              <Button
                className="me-3"
                disabled={recalcComision}
                title={
                  row.getValue("estado_rendicion") === "Pendiente"
                    ? "Cambiar a Vencida"
                    : null
                }
                variant={
                  row.getValue("estado_rendicion") === "Pendiente"
                    ? "secondary"
                    : row.getValue("estado_rendicion") === "Vencida"
                      ? "outline-danger"
                      : "outline-success"
                }
                onClick={() => row.getValue("estado_rendicion") === "Pendiente"
                  ? cambiarEstado(row.getValue("id"))
                  : null}
              >
                {row.getValue("estado_rendicion") === "Pendiente" ? (
                  <BsDashCircleFill />
                ) : row.getValue("estado_rendicion") === "Vencida" ? (
                  <BsFillXSquareFill />
                ) : (
                  <BsCheckCircleFill />
                )}
              </Button>
            </Box>
          )}
        />
        {/* <section className="d-flex justify-content-center my-3">
            <Button
              onClick={null}
              variant="outline-success"
             >
              <BsCheckCircleFill  className="mx-1 mb-1" />
               Pagar Todas
            </Button>
          </section> */}
        <section
          className="d-flex justify-content-end my-3"
          style={{ marginRight: 10 }}
        >
          <BtnVolver route="/liquidaciones/rendiciones" />
          {rolUser === "ADMINISTRADOR" ||
            rolUser === "DOCUMENTACION" ||
            rolUser === "CONTADURIA" ||
            rolUser === "SUCURSAL-CONCEPCION" ||
            rolUser === "GERENCIA-QUINIELA" ? (
            <Button
              className="btn botonCPA"
              onClick={() => setOpenExport(true)}
            >
              {" "}
              <AiOutlineDownload className="iconAntD" />
              Exportar Datos
            </Button>
          ) : null}
        </section>
      </Card>
      <ExportarRendiciones
        isOpen={openExport}
        fecha={fechaDesde}
        onClose={() => {
          setOpenExport(false);
        }}
      />
      <ModalNuevaJugada
        onClose={handleCloseModalNuevaJugada}
        show={showModalNuevaJugada}
        titulo={tituloModal}
      />
      <Modal centered show={isLoading}>
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          <p>Cargando...</p>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Rendiciones;

import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card, Button, Form, Modal, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
    ReadOutlined,
    MailOutlined,
} from '@ant-design/icons';
import MaterialReactTable from 'material-react-table';
import { Box } from '@mui/material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import BtnVolver from '../../../common/BtnVolver';
import Parser from '../../../common/Parser';
import ModalLeerComision from './ModalLeerComision';
import ConsultasAPI from '../../../../shared/helpers/consultasAPI';
import Notificaciones from '../../../../shared/helpers/notificacionesToast';
import { AiOutlineDownload } from 'react-icons/ai';
import { FaEraser } from 'react-icons/fa';
import dayjs from 'dayjs';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ProgressBar from 'react-bootstrap/ProgressBar';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AuthenticationHelper from '../../../../shared/helpers/authenticationHelper';
import { FaPlusCircle } from 'react-icons/fa';
import { Edit } from '@mui/icons-material';
import { ModalFechaCierre } from './ModalFechaCierre';
import Swal from 'sweetalert2';
import EmailComisionesHTML from './PDF/CondicionesPagoHtml';
import ReactDOMServer from 'react-dom/server';

const Comisiones = (props) => {
    const rolUser = props.rolUsuario;

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [periodo, setPeriodo] = useState(
        moment().add(-1, 'months').format('MM/YYYY')
    );
    const [agencias, setAgencias] = useState([]);
    const [estados] = useState([
        { id: true, descripcion: 'Aprobado' },
        { id: false, descripcion: 'Pendiente' },
    ]);
    const [comisionSelected, setComisionSelected] = useState();
    const [agencia, setAgencia] = useState();
    const [estado, setEstado] = useState();
    const [comisionesTabla, setComisionesTabla] = useState([]);
    const [count, setCount] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const URL_JUGADASMES = window.API_ROUTES.JUGADASMES;
    const URL_DEDUCCION = window.API_ROUTES.DEDUCCION;
    const URL_AGENCIAS = window.API_ROUTES.AGENCIAS;
    const URL_COMISION = window.API_ROUTES.COMISION;
    const URL_FECHALIMITE = window.API_ROUTES.FECHALIMITE;
    const URL_BASE = window.API_ROUTES.BASE;
    const URL_REGISTROMAIL = window.API_ROUTES.REGISTROMAIL;
    const URL_CREAR = window.API_ROUTES.FECHALIMITE;

    const [vistaTotal, setVistaTotal] = useState('');
    const [porcentaje, setPorcentaje] = useState(0);
    var valid = function (current) {
        const today = moment();
        const isSunday = current.day() === 0;
        return current.isBefore(today) && !isSunday;
    };

    const [fechaCierre, setfechaCierre] = useState('');
    const [fechaLimite, setfechaLimite] = useState();
    const [fechaCierreModal, setfechaCierreModal] = useState('');
    const [idFecha, setIdFecha] = useState(0);
    const [showModalFechaCierre, setShowModalFechaCierre] = useState(false);
    const [tituloModal, setTituloModal] = useState('');
    const [isLoading, setLoading] = useState(false)
    const handleCloseModalFechaCierre = () => {
        setShowModalFechaCierre(false);
        loadComisiones();
        consultarProceso();
        cargaFechaCierre();
    };

    const handleOpenModalFechaCierre = (operacion) => {
        if (fechaCierre.length !== 0) {
            setfechaCierreModal(fechaCierre);
        } else {
            setfechaCierreModal(FormatoFecha(periodo));
        }
        setTituloModal(operacion);
        setShowModalFechaCierre(true);
    };


    const FormatoFecha = (fecha) => {
        var fechaMoment = moment(fecha, 'MM/YYYY');
        fechaMoment.date(23);
        fechaMoment.add(1, 'month');
        if (fechaMoment.month() === 11) {
            fechaMoment.add(1, 'year');
        }
        var nuevaFecha = fechaMoment.format('DD/MM/YYYY');
        return nuevaFecha;
    };

    const FormatoFechaCierre = (fecha) => {
        const partes = fecha.split(' ');
        const fechaParte = partes[0]; // "23/02/2024"
        const [dia, mes, year] = fechaParte.split('/');
        var fechaFormateada = dia + '/' + mes + '/' + year;
        return fechaFormateada;
    };


    useEffect(() => {
        loadComisiones();
        consultarProceso();
        cargaFechaCierre();

    }, [pagination, agencia, estado, periodo]);

    const cargaFechaCierre = async () => {

        var datos = {
            Fecha: FormatoFecha(periodo),
        };
        await ConsultasAPI.CrearObjeto(
            URL_FECHALIMITE + 'dame_fecha_cierre/',
            datos
        )
            .then((response) => {

                if (response.status === 202) {
                    setfechaCierre('');
                    setfechaLimite();
                }

                if (response.status === 200) {
                    if (response.data[0].dia_limite_nuevo !== null) {
                        setfechaCierre(
                            FormatoFechaCierre(
                                response.data[0].dia_limite_nuevo
                            )
                        );
                    } else {
                        setfechaCierre('');
                    }
                    setIdFecha(response.data[0].id);
                    setfechaLimite(response.data[0]);
                }
            })
            .catch((error) => {
                console.info(error);
                Swal.fire({
                    title: 'Error',
                    text: 'Error del servidor',
                    icon: 'error',
                });
            });
    };

    const loadComisiones = () => {
        const filters = {
            agencia: agencia && agencia !== '-' ? agencia : '',
            aprobado:
                estado !== undefined && estado !== '-'
                    ? estado === 'true' || estado === true
                        ? 'aprobado'
                        : 'pendiente'
                    : '',
            periodo,
        };
        ConsultasAPI.ListarObjetos(
            URL_JUGADASMES,
            pagination.pageIndex,
            pagination.pageSize,
            filters
        ).then((response) => {
            setCount(response.data.count);
            var datos = response.data.results;
            setComisionesTabla(
                datos.map((dato) => {
                    var periodo = dato.periodo.substr(3, 7);
                    return {
                        ...dato,
                        comisionBruta: dato.jugadaMesTotal.recalculado
                            ? dato.comisionBruta
                            : 'Recalcular',
                        aprobado: dato.aprobado ? 'Aprobado' : 'Pendiente',
                        periodo: periodo,
                        codigo: obtenerCodigo(
                            dato.titularAgencia.agencia_detalle.numero_agencia,
                            dato.titularAgencia.agencia_detalle
                                .numero_subagencia
                        ),
                        cuit: dato.titularAgencia.titular
                            ? dato.titularAgencia.titular_detalle
                                .persona_detalle.cuit
                            : 'Sin Responsable',
                    };
                })
            );
        });
        ConsultasAPI.ListarObjetos(URL_AGENCIAS + 'ListaFiltrar/').then(
            (response) => {
                setAgencias(response.data);
            }
        );
    };
    const enviarComisiones = () => {
        navigate('enviar-comisiones');
    };

    const aprobarComisiones = async () => {
        const filters = {
            agencia: agencia && agencia !== '-' ? agencia : '',
            aprobado:
                estado !== undefined && estado !== '-'
                    ? estado === 'true' || estado === true
                        ? 'aprobado'
                        : 'pendiente'
                    : '',
            periodo,
        };

        ConsultasAPI.ListarObjetos(
            URL_JUGADASMES + 'aprobarFiltradas/',
            pagination.pageIndex,
            pagination.pageSize,
            filters
        ).then(async (response) => {
            if (response.status === 200) {
                const comisiones = await ConsultasAPI.CrearObjeto(
                    URL_DEDUCCION + 'crearAutomatizacionCompletas/',
                    1
                );
                setLoading(true)
                await cargarMailsAprobadas(periodo)
                if (comisiones.status === 201 || comisiones.status === 202) {
                    if (comisiones.status === 201) {
                        Notificaciones.notificacion('Se Aprobaron las Comisiones');

                    }

                    if (comisiones.status === 202) {
                        Notificaciones.notificacion('Comisiones aprobadas');
                    }

                    loadComisiones();
                    var datos = {
                        Fecha: FechaParaBase(new Date(Date.now)),
                    };
                    await ConsultasAPI.CrearObjeto(URL_CREAR + 'crear_fecha_cierre/', datos)
                        .then((response) => {
                            if (response.status === 200) {
                                Notificaciones.notificacion('Se creó la fecha de cierre');
                                loadComisiones();
                                consultarProceso();
                                cargaFechaCierre();
                            }


                        })
                        .catch((error) => {
                            console.info(error);
                            Swal.fire({
                                title: 'Error',
                                text: 'Error del servidor',
                                icon: 'error',
                            });
                        });
                }
            } else {
                Notificaciones.notificacion(
                    'No se Pudo Aprobar las Comisiones'
                );
            }
        });




    };

    const FechaParaBase = (fecha) => {
        var formato = new Date(fecha);
        var year = formato.getFullYear();
        var month = (formato.getMonth() + 1).toString().padStart(2, '0');
        var day = formato.getDate().toString().padStart(2, '0');
        var hours = formato.getHours().toString().padStart(2, '0');
        var minutes = formato.getMinutes().toString().padStart(2, '0');
        var seconds = formato.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}-03`;
    };

    function obtenerCodigo(X, Y) {
        const formattedX = String(X).padStart(2, '0'); // Pad X to have two digits
        const formattedY = String(Y).padStart(3, '0'); // Pad Y to have three digits

        return formattedX + formattedY;
    }

    const handleExportExcel = () => {
        var cantidadPedido = 200;
        var partesADescargar = Math.ceil(count / cantidadPedido);
        var partesDescargadas = 0;
        const filters = {
            agencia: agencia && agencia !== '-' ? agencia : '',
            aprobado:
                estado !== undefined && estado !== '-'
                    ? estado
                        ? 'aprobado'
                        : 'pendiente'
                    : '',
            periodo,
            limit: cantidadPedido,
            offset: 0,
        };
        var exportData = [];
        var fechaExport;
        Notificaciones.notificacion('Comenzando descarga...');
        const fetchNextPage = async () => {
            try {
                const response = await ConsultasAPI.ListarObjetos(
                    URL_JUGADASMES + 'exportControl/',
                    pagination.pageIndex,
                    pagination.pageSize,
                    filters
                );
                if (response.data.length !== 0) {
                    fechaExport = response.data[0].periodo.substr(3, 7);
                }
                let peridoElegido;
                if (periodo === moment().format('MM/YYYY')) {
                    peridoElegido = moment();
                } else {
                    peridoElegido = periodo;
                }
                const promises = response.data.map(async (jugada) => {
                    const responseTuqui = await ConsultasAPI.ObtenerObjeto(
                        URL_COMISION + 'obtenerComisionTitular/',
                        jugada.titularAgencia.id +
                        ':' +
                        dayjs(peridoElegido).format('YYYY-MM-DD')
                    );
                    var tuqui;
                    if (responseTuqui.data.length === 0) {
                        tuqui = 0;
                    } else {
                        tuqui = responseTuqui.data[0];
                    }
                    if (
                        parseFloat(jugada.montoVendido) +
                        parseFloat(jugada.montoSinAporte) +
                        parseFloat(jugada.montoFueraDeTermino) >
                        0
                    ) {
                        exportData.push({
                            AG: jugada.titularAgencia.agencia_detalle
                                .numero_agencia,
                            SUB: jugada.titularAgencia.agencia_detalle
                                .numero_subagencia,
                            Titular: jugada.titularAgencia.titular_detalle
                                .persona
                                ? jugada.titularAgencia.titular_detalle
                                    .persona_detalle.apellido +
                                ', ' +
                                jugada.titularAgencia.titular_detalle
                                    .persona_detalle.nombre
                                : 'Sin Responsable',
                            CUIT: jugada.titularAgencia.titular_detalle.persona
                                ? jugada.titularAgencia.titular_detalle
                                    .persona_detalle.cuit
                                : '-',
                            Venta: Parser.currency(
                                parseFloat(jugada.montoVendido) +
                                parseFloat(jugada.montoSinAporte) +
                                parseFloat(jugada.montoFueraDeTermino)
                            ),
                            'Venta Oficial + Sub':
                                jugada.titularAgencia.agencia_detalle
                                    .numero_subagencia === 0
                                    ? Parser.currency(
                                        jugada.jugadaMesTotal.montoVendido
                                    )
                                    : 0,
                            'Comision 12%': Parser.currency(
                                jugada.comisionSubagencia
                            ),
                            'Comision 4%':
                                jugada.titularAgencia.agencia_detalle
                                    .numero_subagencia === 0
                                    ? Parser.currency(jugada.comisionAgencia)
                                    : 0,
                            'Subtotal Quiniela': Parser.currency(
                                jugada.comisionSubagencia +
                                jugada.comisionAgencia
                            ),
                            'Comision 12%  Tuqui':
                                tuqui === 0
                                    ? tuqui
                                    : Parser.currency(tuqui.comision_venta),
                            'Comision 4%  Tuqui':
                                tuqui === 0
                                    ? tuqui
                                    : Parser.currency(
                                        tuqui.comision_venta_total
                                    ),
                            'Subtotal Tuqui':
                                tuqui === 0
                                    ? tuqui
                                    : Parser.currency(tuqui.comision_bruta),
                            'Comision Bruta Total':
                                jugada.titularAgencia.agencia_detalle
                                    .numero_agencia === 0
                                    ? Parser.currency(
                                        jugada.jugadaMesTotal.comisionBruta +
                                        tuqui.comision_bruta
                                    )
                                    : Parser.currency(
                                        jugada.comisionBruta +
                                        (tuqui === 0
                                            ? tuqui
                                            : tuqui.comision_bruta)
                                    ),
                        });
                    }
                });

                await Promise.all(promises);

                filters.limit = filters.limit + cantidadPedido;
                filters.offset = filters.offset + cantidadPedido;
                if (filters.offset >= count) {

                    if (fechaExport) {
                        const worksheet = XLSX.utils.json_to_sheet(
                            exportData.sort(compararPorAgenciaSubagencia).flat()
                        );
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(
                            workbook,
                            worksheet,
                            'Sheet1'
                        );
                        XLSX.writeFile(
                            workbook,
                            'comisiones' + fechaExport.toString() + '.xlsx'
                        );
                        Notificaciones.notificacion('Descarga finalizada');
                    } else {
                        Notificaciones.notificacion(
                            'No se encontraron comisiones'
                        );
                    }
                } else {
                    partesDescargadas = partesDescargadas + 1;
                    Notificaciones.notificacion(
                        'Descargada parte ' +
                        partesDescargadas +
                        ' de ' +
                        partesADescargar
                    );
                    fetchNextPage(); // Recursive call to fetch the next page
                }
            } catch (error) {
                console.error('error de api', error);
                // Notification.showError("Exportacion fallida");
            }
        };

        fetchNextPage(); // Initial call to start fetching pages}
    };

    const compararPorAgenciaSubagencia = (a, b) => {
        // Primero, compara por numero_Agencia
        if (a.AG !== b.AG) {
            return a.AG - b.AG;
        }

        // Si numero_Agencia es igual, compara por numero_subagencia
        return a.SUB - b.SUB;
    };

    const columns = [
        {
            header: 'Periodo de Comisión',
            accessorKey: 'periodo',
            size: 50,
            // Cell: ({ value }) => moment(value,'DD/MM/YYYY HH:mm:ss').format("DD/MM/YYYY"),
        },
        {
            header: 'Agencia',
            accessorKey: 'titularAgencia.agencia_detalle.numero_agencia',
            size: 30,
        },
        {
            header: 'Subagencia',
            accessorKey: 'titularAgencia.agencia_detalle.numero_subagencia',
            size: 30,
        },
        {
            header: 'CUIT',
            accessorKey: 'cuit',
            size: 50,
        },
        {
            header: 'Código',
            accessorKey: 'codigo',
            size: 30,
        },
        {
            header: 'Comisión Bruta',
            accessorKey: 'comisionBruta',
            size: 30,
            Cell: ({ renderedCellValue }) => (
                <span>
                    {renderedCellValue !== 'Recalcular'
                        ? Parser.currency(renderedCellValue)
                        : renderedCellValue}
                </span>
            ),
        },
        {
            header: 'Total de Tickets',
            accessorKey: 'ticketsTotales',
            size: 30,
        },
        {
            header: 'Estado',
            accessorKey: 'aprobado',
            size: 50,
        },
    ];

    const handleClickClear = () => {
        setPeriodo(moment().format('MM/YYYY'));
        setAgencia('-');
        setEstado('-');
    };

    const recalcular = () => {
        const per = moment(periodo, 'MM/YYYY').format('YYYY-MM');
        ConsultasAPI.ObtenerObjeto(URL_JUGADASMES + 'recalcular/', per);
    };

    const consultarProceso = async () => {
        var periodo_cambiado;
        if (moment(periodo).format('MM/YYYY') !== 'Fecha inválida') {
            periodo_cambiado = moment(periodo).format('MM/YYYY');
        } else {
            periodo_cambiado = periodo;
        }
        const jugadas = await ConsultasAPI.ObtenerObjeto(
            URL_JUGADASMES + 'obtenerPorcentajeRecalculadas/',
            periodo_cambiado
        );

        setPorcentaje(jugadas.data['porcentaje']);
        setVistaTotal(
            jugadas.data['total_recalculadas'] + '/' + jugadas.data['total']
        );
    };

    const cargarMailsAprobadas = async (periodoData) => {
        if (periodoData) {
            const filters = {
                agencia: 'TODAS',
                periodo: dayjs(periodoData).format("YYYY-MM-DD HH:mm:ssZ"),
            };
            setLoading(true)
            ConsultasAPI.ListarObjetos(
                URL_JUGADASMES + "noPag/",
                null,
                null,
                filters
            ).then(async (response) => {
                var datos = response.data;
                const promesas =
                    datos
                        .filter((dato) => dato.titularAgencia.titular)
                        .map(async (dato) => {
                            var mail = {
                                mail: dato.titularAgencia.titular_detalle.persona_detalle.email,
                                agencia: 'Ag: ' + dato.titularAgencia.agencia_detalle.numero_agencia + ' - Sub: ' + dato.titularAgencia.agencia_detalle.numero_subagencia,
                                numero_agencia: dato.titularAgencia.agencia_detalle.numero_agencia,
                                numero_subagencia: dato.titularAgencia.agencia_detalle.numero_subagencia,
                            }
                            const Envio = await enviarComisionesTodas(mail, ReactDOMServer.renderToStaticMarkup(
                                <EmailComisionesHTML datos={periodo} />))
                        })
                Promise.all(promesas)
                    .then(async () => {
                        setLoading(false)
                    })
                    .catch((error) => {
                        console.error("Error al procesar las promesas:", error);
                        setLoading(false);
                    });
            })
        }
    };

    const enviarComisionesTodas = async (mail, htmlString) => {
        if (mail) {
            const blob = new Blob([htmlString], { type: 'text/html' });
            const formData = new FormData();
            formData.append('file', blob, mail.mail);
            formData.append('agencia', mail.agencia);
            formData.append('periodo', moment(periodo, "MM/YYYY").format("MM/YYYY"));
            const response = await ConsultasAPI.CrearObjeto(URL_BASE + 'enviar_mail/', formData)
            if (response.status === 200) {
                const data = {
                    agencia: mail.numero_agencia,
                    subagencia: mail.numero_subagencia,
                    mail: mail.mail,
                    concepto: "Envio Comisiones",
                    periodo: periodo.format('YYYY-MM-DD')
                }
                await ConsultasAPI.CrearObjeto(URL_REGISTROMAIL, data);

            } else {
                setLoading(false);
                Swal.fire({
                    title: "No se pudo realizar el envio del mail",
                    icon: "error"
                });
            }
        };
    };

    return (

        <Container className="mainSection">

            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Comisiones</h1>
                </Col>
            </Row>

            {AuthenticationHelper.getRol() === 'ADMINISTRADOR' ||
                AuthenticationHelper.getRol() === 'GERENCIA-QUINIELA'
                ? fechaLimite && (
                    <Card>
                        <Card.Body
                            style={{ padding: '0px', paddingLeft: '25px' }}
                        >
                            <Row>
                                <Col md="6">
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        <div>
                                            <h5 className="py-4 fw-bold">
                                                Fecha de cierre:
                                            </h5>
                                        </div>
                                        <div style={{ paddingLeft: '20px' }}>
                                            {fechaCierre.length === 0 ? (
                                                <h6 className="py-4 fw-bold">
                                                    No posee
                                                </h6>
                                            ) : (
                                                <h6 className="py-4 fw-bold">
                                                    {fechaCierre}
                                                </h6>
                                            )}
                                        </div>
                                        {
                                            AuthenticationHelper.getRol() === "ADMINISTRADOR" || AuthenticationHelper.getRol() === "GERENCIA-QUINIELA" ?
                                                <div style={{ paddingLeft: '30px' }}>
                                                    {fechaCierre.length === 0 ? (
                                                        <Button
                                                            className="botonCPA"
                                                            variant="outline-info"
                                                            onClick={() =>
                                                                handleOpenModalFechaCierre(
                                                                    'Nuevo'
                                                                )
                                                            }
                                                        >
                                                            <FaPlusCircle className="iconAntD" />
                                                            AGREGAR
                                                        </Button>
                                                    ) :
                                                        fechaCierre.length !== 0 ?
                                                            (
                                                                <Button
                                                                    className="botonCPA"
                                                                    variant="outline-info"
                                                                    onClick={() => {
                                                                        handleOpenModalFechaCierre(
                                                                            'Modificar'
                                                                        );
                                                                    }}
                                                                >
                                                                    <Edit className="iconAntD" />
                                                                    MODIFICAR
                                                                </Button>
                                                            ) : null}
                                                </div>
                                                : null
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                )
                : null}

            {AuthenticationHelper.getRol() === 'ADMINISTRADOR' ||
                AuthenticationHelper.getRol() === 'GERENCIA-QUINIELA' ? (
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md="2">
                                {porcentaje === 100 || porcentaje === 0 ?
                                    <Button
                                        className="botonCPA"
                                        style={{ marginLeft: 12 }}
                                        variant="outline-info"
                                        onClick={() => recalcular()}
                                    >
                                        <RotateLeftIcon className="iconAntD" />
                                        Recalcular
                                    </Button>
                                    :
                                    <Button
                                        className="botonCPA"
                                        style={{ marginLeft: 12 }}
                                        variant="outline-info"
                                        disabled
                                    >
                                        <RotateLeftIcon className="iconAntD" />
                                        Recalcular
                                    </Button>
                                }
                            </Col>
                            <Col md="6" style={{ alignContent: 'center' }}>
                                {porcentaje === 100 ? (
                                    <ProgressBar
                                        style={{ width: '100%' }}
                                        now={porcentaje}
                                        variant="success"
                                        label={`${porcentaje}%`}
                                    />
                                ) : (
                                    <ProgressBar
                                        style={{ width: '100%' }}
                                        animated
                                        now={porcentaje}
                                        variant="success"
                                        label={`${porcentaje}%`}
                                    />
                                )}
                            </Col>
                            <Col md="2" style={{ alignContent: 'center' }}>
                                <h4>{vistaTotal}</h4>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            ) : null}
            <Card>
                <Card.Body>
                    <Row>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Periodo</Form.Label>
                            <Datetime
                                isValidDate={valid}
                                timeFormat={false}
                                dateFormat="MM/YYYY"
                                inputProps={{
                                    readOnly: true,
                                    placeholder: periodo,
                                }}
                                value={periodo}
                                onChange={setPeriodo}
                                allowClear
                                closeOnSelect
                            // onClear={() => setPeriodo("")}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Agencia</Form.Label>
                            <Form.Select
                                // style={{ width: "20%", height: "37px" }}
                                value={agencia}
                                onChange={(event) =>
                                    setAgencia(event.target.value)
                                }
                                disabled={agencias.length < 1}
                            >
                                <option hidden>-</option>
                                {agencias
                                    .filter(
                                        (agencia) =>
                                            agencia.numero_subagencia === 0
                                    )
                                    .map((agencia) => (
                                        <option
                                            key={agencia.id}
                                            value={agencia.numero_agencia}
                                        >
                                            {agencia.numero_agencia}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Estado</Form.Label>
                            <Form.Select
                                // style={{ width: "20%", height: "37px" }}
                                value={estado}
                                onChange={(event) =>
                                    setEstado(event.target.value)
                                }
                                disabled={estados.length < 1}
                            >
                                <option hidden>-</option>
                                {estados.map((estado) => (
                                    <option key={estado.id} value={estado.id}>
                                        {estado.descripcion}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Button
                                className="btn botonCPA me-3"
                                onClick={handleClickClear}
                                style={{
                                    alignContent: 'center',
                                    margin: 15,
                                    float: 'right',
                                }}
                            >
                                <FaEraser className="iconAntD" />
                                Limpiar Filtro
                            </Button>
                        </Form.Group>
                    </Row>
                </Card.Body>
                <MaterialReactTable
                    columns={columns}
                    data={comisionesTabla}
                    initialState={{ columnVisibility: { id: false } }} //hide firstName column by default
                    editingMode="modal" //default
                    enableEditing
                    // onEditingRowSave={handleSaveRowEdits}
                    // onEditingRowCancel={handleCancelRowEdits}
                    enableRowSelection={false} //enable some features
                    enableColumnOrdering={false}
                    enableGlobalFilter={true} //turn off a feature
                    enableFilters={false}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    positionActionsColumn="last"
                    manualPagination
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [10],
                    }}
                    enablePagination={true}
                    rowCount={count}
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    // state={{ pagination }} //pass the pagination state to the table
                    state={{
                        pagination,
                    }}
                    renderRowActions={({ row }) => (
                        <Box className="d-flex">
                            <Button
                                className="me-3"
                                title="Editar"
                                variant="outline-info"
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setComisionSelected(row.original);
                                }}
                            >
                                <ReadOutlined />
                            </Button>
                        </Box>
                    )}
                />
                <section
                    className="d-flex justify-content-end my-3"
                    style={{ marginRight: 10 }}
                >
                    <BtnVolver />
                    {rolUser === 'ADMINISTRADOR' ||
                        rolUser === 'GERENCIA-QUINIELA' ? (
                        <>
                            <Button
                                className="botonCPA"
                                onClick={aprobarComisiones}
                                style={{ marginRight: '1%' }}
                            >
                                <PlaylistAddCheckIcon className="iconAntD" />{' '}
                                Aprobar comisiones
                            </Button>
                        </>
                    ) : null}
                    {rolUser === 'ADMINISTRADOR' ||
                        rolUser === 'GERENCIA-QUINIELA' ? (
                        <>
                            <Button
                                className="botonCPA"
                                onClick={enviarComisiones}
                            >
                                <MailOutlined className="iconAntD" /> Enviar
                                comisiones
                            </Button>
                        </>
                    ) : null}
                    <Button
                        className="botonCPA"
                        style={{ marginLeft: 12 }}
                        variant="outline-info"
                        disabled={!periodo}
                        onClick={() => handleExportExcel()}
                    >
                        <AiOutlineDownload className="iconAntD" />
                        Exportar Excel
                    </Button>
                </section>
            </Card>
            <ModalLeerComision
                isOpen={isModalOpen}
                comision={comisionSelected}
                onClose={() => {
                    setIsModalOpen(false);
                    loadComisiones();
                }}
                rolUsuario={rolUser}
            />

            <ModalFechaCierre
                onClose={handleCloseModalFechaCierre}
                show={showModalFechaCierre}
                titulo={tituloModal}
                periodo={fechaCierreModal}
                idFecha={idFecha}
            />
            <Modal centered show={isLoading}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Cargando...</p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default Comisiones;

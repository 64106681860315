/* eslint-disable */

import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Card, Form, Modal, Container, Button } from "react-bootstrap";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
import Swal from "sweetalert2";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import { darken } from "@mui/material";
import { Switch } from '@mui/material';
import { IconButton } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import BtnVolver from "../../common/BtnVolver";
import { FaRegEdit } from "react-icons/fa";
import PaletteIcon from '@mui/icons-material/Palette';


const SubirPublicidad = (props) => {
    const [media, setMedia] = useState(null);
    const [video, setVideo] = useState(false);
    const [duracion, setDuracion] = useState('');
    const [idArchivo, setIdArchivo] = useState('');
    const [idTipo, setIdTipo] = useState('');
    const URL_PUBLICIDAD = window.API_ROUTES.PUBLICIDAD;
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [count, setCount] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [showModalEditarArchivo, setShowModalEditarArchivo] = useState(false);
    const [showModalEditarColor, setShowModalEditarColor] = useState(false);
    const [showModalColores, setShowModalColores] = useState(false);

    const [data, setData] = useState([]);
    const [dataTipos, setDataTipos] = useState([]);
    const [color, setColor] = useState([]);
    const URL_TIPOSORTEO = window.API_ROUTES.TIPOSORTEO;
    const columns = useMemo(() => [
        {
            header: 'id',
            accessorKey: 'id',
            enableColumnFilter: false,
            size: 50,
        },
        {
            header: 'Nombre',
            accessorKey: 'nombre',
            enableColumnFilter: false,
            size: 50,
        },
        {
            header: 'Archivo',
            accessorKey: 'media',
            enableColumnFilter: false,
            size: 50,
        },
        {
            header: 'Duracion',
            accessorKey: 'duracion',
            size: 140,
        },
        {
            header: 'Estado',
            accessorKey: 'estado',
            size: 140,
        },
    ]);
    const columnsTipos = useMemo(() => [
        {
            header: 'id',
            accessorKey: 'id',
            enableColumnFilter: false,
            size: 50,
        },
        {
            header: 'Tipo',
            accessorKey: 'tipo',
            enableColumnFilter: false,
            size: 50,
        },
        {
            header: 'Color',
            accessorKey: 'color',
            enableColumnFilter: false,
            size: 50,
        },
        {
            header: 'Codigo',
            accessorKey: 'color_codigo',
            enableColumnFilter: false,
            size: 50,
        },
    ]);
    const handleClose = (event) => {
        props.closeModal(false)
    };

    useEffect(() => {
        obtenerPublicidad();
        obtenerTiposSorteo();
    }, []);

    const handleMediaChange = (event) => {
        const archivo = {
            path: event.target.files[0],
        };
        if (event.target.files[0].type.split('/')[0] === 'video') {
            setVideo(true)
        }
        setMedia(archivo);
    };

    const obtenerPublicidad = async () => {
        const publicidad = await ConsultasAPI.ObtenerObjeto(URL_PUBLICIDAD + 'obtener_todas/', 1)
        const datosTabla = [];
        publicidad.data.forEach(publi => {
            if (publi.extracto) {
                datosTabla.push({
                    id: publi.id,
                    nombre: 'Extracto',
                    media: '',
                    duracion: publi.duracion,
                    estado: publi.estado_publicidad ? 'Activo' : 'No activo',
                    estado_publicidad: publi.estado_publicidad,
                });
            } else {
                datosTabla.push({
                    id: publi.id,
                    nombre: publi.media.split('/').pop(),
                    media: (<a
                        href={publi.media}
                        target="blank"
                    >
                        <IconButton>
                            {
                                publi.video ?
                                    <VideocamIcon />
                                    :
                                    <PhotoCameraIcon />
                            }
                        </IconButton>
                    </a>),
                    duracion: publi.duracion,
                    estado: publi.estado_publicidad ? 'Activo' : 'No activo',
                    estado_publicidad: publi.estado_publicidad,
                });
            }
        });
        setData(datosTabla)
    }

    const crearPublicidad = async (event) => {
        event.preventDefault();
        Swal.fire({
            title: '¿Estás seguro de subir el archivo?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let formData = new FormData();
                formData.append('media', media.path)
                formData.append('video', video)
                formData.append('duracion', '00:' + duracion)
                formData.append('creado_por', AuthenticationHelper.getUser())

                const creacion = await ConsultasAPI.CrearObjetoArchivo(URL_PUBLICIDAD, formData);
                if (creacion.statusText === "Created") {
                    Swal.fire(
                        'Subida exitosa',
                        'El archivo se subio con exito',
                        'success'
                    );
                    obtenerPublicidad();
                    setMedia(null);
                    setDuracion("");
                    handleClose(event);
                } else {
                    Swal.fire(
                        'Subida erronea',
                        'El archivo no se subio con exito',
                        'error'
                    );
                };
            };
        });

    };

    const activarDesactivar = async (id_archivo) => {
        Swal.fire({
            title: '¿Estás seguro de cambiar el estado del archivo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const cambio = await ConsultasAPI.ObtenerObjeto(URL_PUBLICIDAD + 'cambiar_estado/', id_archivo);
                if (cambio.status === 200) {
                    Swal.fire(
                        'Cambio de estado exitoso',
                        'Se cambio de estado el archivo',
                        'success'
                    );
                    obtenerPublicidad();
                } else {
                    Swal.fire(
                        'Cambio de estado erroneo',
                        'No se pudo cambiar de estado el archivo',
                        'error'
                    );
                };
            };
        });
    }
    const borrarArchivo = async (id_archivo) => {
        Swal.fire({
            title: '¿Estás seguro de cambiar el estado del archivo?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const cambio = await ConsultasAPI.ObtenerObjeto(URL_PUBLICIDAD + 'eliminar/', id_archivo);
                if (cambio.status === 200) {
                    Swal.fire(
                        'Borrado exitoso',
                        'Se borro con exito el archivo',
                        'success'
                    );
                    obtenerPublicidad();
                } else {
                    Swal.fire(
                        'Borreado erroneo',
                        'No se pudo borrar el archivo',
                        'error'
                    );
                };
            };
        });
    }
    const editarArchivo = async (id_archivo) => {
        setShowModalEditarArchivo(true);
        setIdArchivo(id_archivo);
    };

    const editarColor = async (row) => {
        setShowModalEditarColor(true);
        setIdTipo(row.id);
        setColor(row.color_codigo)
    };

    const submitEdicion = async (event) => {
        event.preventDefault()
        Swal.fire({
            title: '¿Estás seguro de editar la duracion del archivo?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const cambio = await ConsultasAPI.ModificarObjeto(URL_PUBLICIDAD + 'editarDuracion/', idArchivo, { duracion: duracion });
                if (cambio.status === 200) {
                    Swal.fire(
                        'Edición exitosa',
                        'Se edito con exito el archivo',
                        'success'
                    );
                    obtenerPublicidad();
                    setIdArchivo('');
                    setDuracion('');
                    setShowModalEditarArchivo(false)
                } else {
                    Swal.fire(
                        'Edición erronea',
                        'No se pudo borrar el archivo',
                        'error'
                    );
                };
            };
        });
    }

    const obtenerTiposSorteo = async () => {
        const tipos = await ConsultasAPI.ListarTodos(URL_TIPOSORTEO)
        let data = []

        tipos.data.results.forEach(tipo => {
            let color = tipo.color

            data.push({
                id: tipo.id,
                tipo: tipo.tipo,
                color: (
                    <div
                        style={{
                            width: `60%`,
                            height: `20px`,
                            backgroundColor: color,
                            alignSelf: 'center'
                        }}
                    />
                ),
                color_codigo: color,
            });
        });
        setDataTipos(data)
    }

    const submitColor = async (event) => {
        event.preventDefault()
        Swal.fire({
            title: '¿Estás seguro de editar la duracion del archivo?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const cambio = await ConsultasAPI.ModificarObjeto(URL_TIPOSORTEO + 'editarColor/', idTipo, { color: color });
                if (cambio.status === 200) {
                    Swal.fire(
                        'Edición exitosa',
                        'Se edito con exito el color',
                        'success'
                    );
                    obtenerTiposSorteo();
                    setIdTipo('');
                    setColor('');
                    setShowModalEditarColor(false);
                } else {
                    Swal.fire(
                        'Edición erronea',
                        'No se pudo editar el color',
                        'error'
                    );
                };
            };
        });
    }
    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Publicidad</h1>
                </Col>
            </Row>
            <Card>
                <Card.Header>
                    <Card.Title>Subir Media</Card.Title>
                </Card.Header>
                <Form onSubmit={crearPublicidad}>
                    <Row>
                        <div className="d-flex justify-content-center m-3">
                            <Col md="7" >
                                <Form.Group>
                                    <Form.Label>Media</Form.Label>
                                    <Form.Control
                                        required
                                        type="file"
                                        onChange={(event) => { handleMediaChange(event) }}
                                        accept=".mp4,.jpg,.png"
                                    />
                                </Form.Group>
                            </Col>

                            <Col md="3" style={{ marginLeft: '1%' }}>
                                <Form.Group>
                                    <Form.Label>Duracion</Form.Label>
                                    <Form.Control
                                        type="time"
                                        required
                                        onChange={(event) => { setDuracion(event.target.value) }}
                                        value={duracion}
                                    />
                                </Form.Group>
                            </Col>

                        </div>
                    </Row>
                    <Col md="2" style={{ float: 'right', marginBottom: '1%' }}>
                        <Form.Group>
                            <Button className="botonCPA" type="submit">
                                Subir
                            </Button>
                        </Form.Group>
                    </Col>
                </Form>
            </Card>
            <Card style={{ marginTop: '1%' }}>
                <Card.Header>
                    <Card.Title>Media</Card.Title>
                </Card.Header>
                <MaterialReactTable
                    className="w-100"
                    columns={columns}
                    data={data}
                    muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: '0',
                            border: '1px dashed #e0e0e0',
                        },
                    }}
                    muiTableBodyProps={{
                        sx: (theme) => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: darken(
                                    theme.palette.background.default,
                                    0.1
                                ),
                            },
                            fontFamily: 'Roboto, sans-serif', // Configuración de la tipografía para las filas pares
                        }),
                    }}
                    initialState={{
                        columnVisibility: { id: false },
                        showColumnFilters: true,
                    }} //hide firstName column by default
                    editingMode="modal" //default
                    // enableEditing
                    enableRowSelection={false} //enable some features
                    enableColumnOrdering={false}
                    enableHiding={false}
                    enableSorting={true}
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [10],
                    }}
                    enablePagination={true}
                    // localization={MRT_Localization_ES}
                    // rowCount={count}
                    // onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    enableGlobalFilter={false} //turn off a feature
                    manualFiltering //turn off client-side filtering
                    onColumnFiltersChange={(value) => {
                        setColumnFilters(value);
                    }} //hoist internal columnFilters state to your state
                    manualSorting
                    // onSortingChange={setSorting}
                    state={{
                        // columnFilters,
                        // pagination,
                        // sorting,
                    }}
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => (
                        <div style={{ display: 'flex' }}>
                            {
                                row.original.nombre !== 'Extracto' ?
                                    <>
                                        <Switch
                                            checked={row.original.estado_publicidad}
                                            onChange={() => { activarDesactivar(row.original.id) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <IconButton title="Eliminar archivo" onClick={() => { borrarArchivo(row.original.id) }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                    : null
                            }
                            <IconButton title="Editar Duracion" onClick={() => { editarArchivo(row.original.id) }}>
                                <FaRegEdit />
                            </IconButton>
                        </div>
                    )

                    }
                />
            </Card>
            {
                AuthenticationHelper.getRol() === "RELACIONES" ?
                    null :
                    <section className="d-flex justify-content-end my-3">
                        <BtnVolver route="/extractos" />
                        <Button className="botonCPA" onClick={() => { setShowModalColores(true) }} ><PaletteIcon /> Colores</Button>
                    </section>
            }
            <Modal show={showModalEditarArchivo}>
                <Modal.Header closeButton onClick={() => { setShowModalEditarArchivo(false) }}>
                    <Modal.Title>
                        Editar Duracion
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={submitEdicion}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Duracion</Form.Label>
                            <Form.Control
                                type="time"
                                required
                                onChange={(event) => { setDuracion(event.target.value) }}
                                value={duracion}
                            />
                        </Form.Group>
                        <Button className="botonCPA" type="submit">Subir</Button>
                    </Modal.Body>
                </Form>
            </Modal>
            <Modal show={showModalColores} size="lg">
                <Modal.Header closeButton onClick={() => { setShowModalColores(false) }}>
                    <Modal.Title>
                        Colores de los Extractos
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={submitEdicion}>
                    <Modal.Body>
                        <MaterialReactTable
                            className="w-100"
                            columns={columnsTipos}
                            data={dataTipos}
                            muiTablePaperProps={{
                                elevation: 0,
                                sx: {
                                    borderRadius: '0',
                                    border: '1px dashed #e0e0e0',
                                },
                            }}
                            muiTableBodyProps={{
                                sx: (theme) => ({
                                    '& tr:nth-of-type(odd)': {
                                        backgroundColor: darken(
                                            theme.palette.background.default,
                                            0.1
                                        ),
                                    },
                                    fontFamily: 'Roboto, sans-serif', // Configuración de la tipografía para las filas pares
                                }),
                            }}
                            initialState={{
                                columnVisibility: { id: false },
                                showColumnFilters: true,
                            }} //hide firstName column by default
                            editingMode="modal" //default
                            // enableEditing
                            enableRowSelection={false} //enable some features
                            enableColumnOrdering={false}
                            enableHiding={false}
                            enableSorting={true}
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [10],
                            }}
                            enablePagination={true}
                            // localization={MRT_Localization_ES}
                            // rowCount={count}
                            // onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                            enableGlobalFilter={false} //turn off a feature
                            manualFiltering //turn off client-side filtering
                            onColumnFiltersChange={(value) => {
                                setColumnFilters(value);
                            }} //hoist internal columnFilters state to your state
                            manualSorting
                            // onSortingChange={setSorting}
                            state={{
                                // columnFilters,
                                // pagination,
                                // sorting,
                            }}
                            enableRowActions
                            positionActionsColumn="last"
                            renderRowActions={({ row }) => (
                                <div style={{ display: 'flex' }}>
                                    <IconButton title="Editar Duracion" onClick={() => { editarColor(row.original) }}>
                                        <FaRegEdit />
                                    </IconButton>
                                </div>
                            )

                            }
                        />

                    </Modal.Body>
                </Form>
            </Modal>
            <Modal show={showModalEditarColor} size="sm">
                <Modal.Header closeButton onClick={() => { setShowModalEditarColor(false) }}>
                    <Modal.Title>
                        Editar Color
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={submitColor}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Color</Form.Label>
                            <Form.Control
                                type="color"
                                id="colorInput"
                                defaultValue={color}
                                title="Choose your color"
                                onBlur={(event) => { setColor(event.target.value) }}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{ float: 'right' }} className="botonCPA" type="submit">Guardar</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Container>
    )
}
export default SubirPublicidad;

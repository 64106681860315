import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import moment from "moment"
import Datetime from "react-datetime";
import Notificaciones from "../../../shared/helpers/notificacionesToast";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { FaFileDownload } from "react-icons/fa";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';
const ModalReporte = (props) => {
    const [fecha_inicio, setFechaInicio] = useState({ fechaMuestra: null, fechaComparar: null })
    const [fecha_fin, setFechaFin] = useState({ fechaMuestra: null, fechaComparar: null })
    const URL_EXTRACTO = window.API_ROUTES.EXTRACTO;
    const [excel, setExcel] = useState()

    const handleFechaInicio = (momentDate) => {
        const fecha_comparar = momentDate.format("YYYY-MM");
        const fecha_muestra = momentDate.format("MM/YYYY");
        setFechaInicio({ fechaMuestra: fecha_muestra, fechaComparar: fecha_comparar });
    };
    const handleFechaFin = (momentDate) => {
        const fecha_comparar = momentDate.format("YYYY-MM");
        const fecha_muestra = momentDate.format("MM/YYYY");
        setFechaFin({ fechaMuestra: fecha_muestra, fechaComparar: fecha_comparar });
    };
    const reporte = async (event) => {
        event.preventDefault()
        if (fecha_inicio.fechaComparar && fecha_fin.fechaComparar) {
            const fechas = {
                fecha_inicio: fecha_inicio.fechaComparar,
                fecha_fin: fecha_fin.fechaComparar,
            };
            const reporte = await ConsultasAPI.CrearObjeto(
                URL_EXTRACTO + 'reporte/', fechas
            );
            if (excel) {
                generarExcel(reporte.data['ventas'], reporte.data['pagos'], reporte.data['tickets'], reporte.data['puntos_de_venta'])
            } else {
                generarPDF(reporte.data['ventas'], reporte.data['pagos'], reporte.data['tickets'], reporte.data['puntos_de_venta'])
            }
        } else {
            Notificaciones.notificacion("Debe elegir ambas fechas")
        };
    };

    function generarPDF(ventas, pagos, tickets, puntos_venta) {
        return new Promise((resolve) => {
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            const img = new Image();
            img.src = require('../../../img/logo.png'); // Utiliza la ruta de importación directa
            const img_quiniela = new Image();
            img_quiniela.src = require('../../../img/logo-quiniela-prueba.png'); // Utiliza la ruta de importación directa

            img.onload = () => {
                const canvas1 = document.createElement('canvas');
                canvas1.width = img.width;
                canvas1.height = img.height;
                const ctx1 = canvas1.getContext('2d');
                ctx1.drawImage(img, 0, 0);
                const dataURL = canvas1.toDataURL('image/png');
                img_quiniela.onload = () => {
                    // Segundo canvas para la segunda imagen
                    const canvas2 = document.createElement('canvas');
                    canvas2.width = img_quiniela.width;
                    canvas2.height = img_quiniela.height;
                    const ctx2 = canvas2.getContext('2d');
                    ctx2.drawImage(img_quiniela, 0, 0);
                    const dataURL2 = canvas2.toDataURL('image/png');
                    var body = ventas.map((data) => {
                        const linea = []
                        linea.push(Object.keys(data)[0])
                        Object.values(data)[0].map((valor) => {
                            linea.push(Object.values(valor).toLocaleString('es-AR'))
                        })
                        return linea;
                    })
                    var body2 = tickets.map((data) => {
                        const linea = []
                        linea.push(Object.keys(data)[0])
                        Object.values(data)[0].map((valor) => {
                            linea.push(Object.values(valor).toLocaleString('es-AR'))
                        })
                        return linea;
                    })
                    var body3 = puntos_venta.map((data) => {
                        const linea = []
                        linea.push(Object.keys(data)[0])
                        linea.push(Object.values(data)[0])
                        return linea;
                    })
                    var body4 = pagos.map((data) => {
                        const linea = []
                        linea.push(Object.keys(data))
                        Object.values(Object.values(data)[0]).map((valor) => {
                            linea.push(valor.toLocaleString("es-AR"))
                        })
                        return linea;
                    })
                    const documentDefinition = {
                        // pageOrientation: "landscape",
                        pageSize: 'A4',
                        pageMargins: [20, 10, 20, 10],
                        content: [
                            {
                                absolutePosition: { x: 40, y: 10 }, // Coordenadas x, y para la imagen
                                image: 'sampleImage', // Referencia a la imagen definida en 'images'
                                color: 'white',
                                width: 150.6 / 2, // Cambia el ancho de la imagen
                                height: 88.8 / 2, // Cambia la altura de la imagen
                            },
                            {
                                absolutePosition: { x: 500, y: 10 }, // Coordenadas x, y para la imagen
                                image: 'quiniela', // Referencia a la imagen definida en 'images'
                                color: 'white',
                                width: 150.6 / 2, // Cambia el ancho de la imagen
                                height: 88.8 / 2, // Cambia la altura de la imagen
                            },
                            {
                                text: 'Caja Popular de Ahorros',
                                style: 'header',
                                alignment: 'center',
                            },
                            {
                                headerRows: 1,
                                style: 'tableExample1',
                                alignment: 'center',
                                table: {
                                    body: [
                                        [
                                            {
                                                text: 'Año - Mes',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'Total - Ventas $',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'Total - Premios $',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'Total - Netwin $',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'Total - Netwin %',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                        ],
                                        ...body4,
                                    ],
                                },
                            },
                            {
                                headerRows: 1,
                                style: 'tableExample',
                                alignment: 'center',
                                table: {
                                    body: [
                                        [
                                            {
                                                text: 'Ventas',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'V Mat',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'V ves',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'V Sies',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'V Tarde',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'V Noct',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'V TOTAL',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                        ],
                                        ...body,
                                    ],
                                },
                            },
                            {
                                headerRows: 1,
                                style: 'tableExample',
                                alignment: 'center',
                                table: {
                                    body: [
                                        [
                                            {
                                                text: 'Tickets',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'T Mat',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'T ves',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'T Sies',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'T Tarde',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'T Noct',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'T TOTAL',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                        ],
                                        ...body2,
                                    ],
                                },
                            },
                            {
                                headerRows: 1,
                                style: 'tableExample',
                                alignment: 'center',
                                table: {
                                    body: [
                                        [
                                            {
                                                text: 'Año - Mes',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                            {
                                                text: 'Puntos de venta',
                                                style: 'tableHeader',
                                                alignment: 'center',
                                            },
                                        ],
                                        ...body3,
                                    ],
                                },
                            },
                        ],
                        styles: {
                            header: {
                                fontSize: 18,
                                bold: true,
                                margin: [0, 10, 0, 10],
                            },
                            subheader: {
                                fontSize: 16,
                                bold: true,
                                margin: [0, 5, 0, 5],
                            },
                            text: {
                                fontSize: 10,
                                bold: false,
                                // margin: [0, 10, 0, 5]
                            },
                            tableExample: {
                                margin: [0, 5, 0, 15],
                            },
                            tableExample1: {
                                margin: [0, 10, 0, 15],
                            },
                            tableHeader: {
                                bold: true,
                                fontSize: 13,
                                color: 'black',
                            },
                        },
                        images: {
                            sampleImage: dataURL, // Reemplaza con el dataURL de tu imagen
                            quiniela: dataURL2,
                        },
                        // background: [
                        //     {
                        //         absolutePosition: { x: 40, y: 10 }, // Coordenadas x, y para la imagen
                        //         image: 'sampleImage', // Referencia a la imagen definida en 'images'
                        //         color: 'white',
                        //         width: 150.6 / 2, // Cambia el ancho de la imagen
                        //         height: 88.8 / 2, // Cambia la altura de la imagen
                        //     },
                        //     {
                        //         absolutePosition: { x: 500, y: 10 }, // Coordenadas x, y para la imagen
                        //         image: 'quiniela', // Referencia a la imagen definida en 'images'
                        //         color: 'white',
                        //         width: 150.6 / 2, // Cambia el ancho de la imagen
                        //         height: 88.8 / 2, // Cambia la altura de la imagen
                        //     },
                        // ],
                    };
                    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

                    // Descarga el PDF
                    pdfDocGenerator.download('Indicador Semestral ' + moment(fecha_inicio.fechaMuestra, "MM/YYYY").format("MM/YYYY") + ' - ' + moment(fecha_fin.fechaMuestra, "MM/YYYY").format("MM/YYYY") + '.pdf');

                    // Obtén el PDF en formato base64 y realiza el POST
                    pdfDocGenerator.getBlob((blob) => {
                        resolve(blob);
                    });
                };
            };
        });
    };

    function generarExcel(ventas, pagos, tickets, puntos_venta) {
        // Crear las hojas de Excel con los datos
        var body = ventas.map((data) => {
            const linea = []
            linea.push(Object.keys(data)[0])
            Object.values(data)[0].map((valor) => {
                linea.push(Object.values(valor).toLocaleString('es-AR'))
            })
            return linea;
        })
        var body2 = tickets.map((data) => {
            const linea = []
            linea.push(Object.keys(data)[0])
            Object.values(data)[0].map((valor) => {
                linea.push(Object.values(valor).toLocaleString('es-AR'))
            })
            return linea;
        })
        var body3 = puntos_venta.map((data) => {
            const linea = []
            linea.push(Object.keys(data)[0])
            linea.push(Object.values(data)[0])
            return linea;
        })
        var body4 = pagos.map((data) => {
            const linea = []
            linea.push(Object.keys(data))
            Object.values(Object.values(data)[0]).map((valor) => {
                linea.push(valor.toLocaleString("es-AR"))
            })
            return linea;
        })
        const ws = XLSX.utils.aoa_to_sheet([
            ["Año - Mes", "Total - Ventas $", "Total - Premios $", "Total - Netwin $", "Total - Netwin %"],
            ...body4, // La primera tabla de pagos
            [],
            ["Ventas", "V Mat", "V Ves", "V Sies", "V Tarde", "V Noct", "V TOTAL"],
            ...body, // La segunda tabla de ventas
            [],
            ["Tickets", "T Mat", "T Ves", "T Sies", "T Tarde", "T Noct", "T TOTAL"],
            ...body2, // La tercera tabla de tickets
            [],
            ["Año - Mes", "Puntos de venta"],
            ...body3 // La cuarta tabla de puntos de venta
        ]);

        // Estilos para las celdas de la tabla
        // Asegúrate de aplicar los estilos a las celdas correctamente
        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } }, // Texto en negrita y color blanco
            fill: { fgColor: { rgb: "DA9694" } }, // Color de fondo del encabezado
            alignment: { horizontal: "center", vertical: "center" }, // Alineación centrada
            border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
            }
        };

        const cellStyle = {
            fill: { fgColor: { rgb: "F2DCDB" } },
            border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
            },
            alignment: { horizontal: "center", vertical: "center" },
        };
        // Aplica los estilos al encabezado
        function aplicarEstilosAHoja(ws, inicioTablas, headerStyle, cellStyle) {
            inicioTablas.forEach((inicio) => {
                const { filaInicio, filaFin, colInicio, colFin } = inicio;

                // Estilos para los encabezados de cada tabla
                for (let col = colInicio; col <= colFin; col++) {
                    const cellAddress = `${String.fromCharCode(65 + col)}${filaInicio}`;
                    if (ws[cellAddress]) {
                        ws[cellAddress].s = headerStyle;
                    }
                }

                // Estilos para el resto de las celdas de cada tabla
                for (let row = filaInicio + 1; row <= filaFin + 1; row++) {
                    for (let col = colInicio; col <= colFin; col++) {
                        const cellAddress = `${String.fromCharCode(65 + col)}${row}`;
                        if (ws[cellAddress]) {
                            ws[cellAddress].s = cellStyle;
                        }
                    }
                }
            });
        }

        // Definir el rango de cada tabla para aplicar estilos
        const inicioTablas = [
            { filaInicio: 1, filaFin: body4.length + 1, colInicio: 0, colFin: 4 }, // Primera tabla
            { filaInicio: body4.length + 3, filaFin: body4.length + 2 + body.length, colInicio: 0, colFin: 6 }, // Segunda tabla
            { filaInicio: body4.length + body.length + 5, filaFin: body4.length + body.length + 4 + body2.length, colInicio: 0, colFin: 6 }, // Tercera tabla
            { filaInicio: body4.length + body.length + body2.length + 7, filaFin: body4.length + body.length + body2.length + 6 + body3.length, colInicio: 0, colFin: 1 } // Cuarta tabla
        ];

        // Llamar a la función para aplicar los estilos
        aplicarEstilosAHoja(ws, inicioTablas, headerStyle, cellStyle);

        // Crear un archivo de Excel con las hojas
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Reporte'); // Agrega la hoja de trabajo al libro

        // Descarga el archivo de Excel
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'Indicador Semestral ' + moment(fecha_inicio.fechaMuestra, "MM/YYYY").format("MM/YYYY") + ' - ' + moment(fecha_fin.fechaMuestra, "MM/YYYY").format("MM/YYYY") + '.xlsx');
    };
    return (
        <Modal centered show={props.show}>
            <Modal.Header closeButton onClick={props.close}>
                <Modal.Title>Reportes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={reporte}>
                    <Form.Group>
                        <Form.Label>Fecha Inicio</Form.Label>
                        <Datetime
                            timeFormat={false}
                            dateFormat="MM/YYYY"
                            inputProps={{
                                readOnly: true,
                                placeholder: "Elegir fecha",
                            }}
                            value={fecha_inicio.fechaMuestra}
                            onChange={handleFechaInicio}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fecha Fin</Form.Label>
                        <Datetime
                            timeFormat={false}
                            dateFormat="MM/YYYY"
                            inputProps={{
                                readOnly: true,
                                placeholder: "Elegir fecha",
                            }}
                            value={fecha_fin.fechaMuestra}
                            onChange={handleFechaFin}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex justify-content-center m-3">
                        <Button className="btn botonCPA-PDF me-3" type="submit" onClick={() => { setExcel(false) }}>
                            <FaFileDownload className="iconAntD" />
                            Descargar PDF</Button>
                        <Button className="btn botonCPA me-3" type="submit" onClick={() => { setExcel(true) }}>
                            <FaFileDownload className="iconAntD" />
                            Descargar Excel</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
export default ModalReporte;

import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner, Form } from "react-bootstrap";
import Datetime from "react-datetime";
import { FaStepBackward } from "react-icons/fa";
import { AiOutlineDownload, AiFillCopy, AiOutlineSend } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import MovimientosDeOperaciones from "./PDF/MovimientosDeOperaciones";
import ConsultasAPI from "../../../../shared/helpers/consultasAPI";
import Parser from "../../../common/Parser";
import moment from "moment";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";
import ReciboRendicionesLinea from "./PDF/GenerarRendicionesLinea";

function ModalLeerRendicion(props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [tablaTipo, setTablaTipo] = useState([]);
  const URL_RENDICION_AGENCIA = window.API_ROUTES.RENDICIONAGENCIA;
  const URL_TIPOSUCURSAL = window.API_ROUTES.TIPOSUCURSAL;

  const [fecha, setFecha] = useState(moment().format("YYYY-MM-DD"));
  const [fechaRendicion, setFechaRendicion] = useState();
  const [fechaSorteo, setFechaSorteo] = useState();
  const [rendicionesExport, setRendicionesExport] = useState([]);
  const [rendicionesRecibos, setRendicionesRecibos] = useState([]);
  const [rendicionesRecibosVen, setRendicionesRecibosVen] = useState([]);
  const [rendicionesRecibosPen, setRendicionesRecibosPen] = useState([]);
  const generarRecibos = () => {
    navigate("/liquidaciones/rendiciones/generar-recibos-rendiciones", {
      state: { rendiciones: rendicionesRecibos },
    });
  };
  const [tipoSucursal, setTipoSucursal] = useState({ id: "" });

  useEffect(() => {
    setIsOpen(props.isOpen);
    cargarTablaTipo();
  }, [props.isOpen]);

  useEffect(() => {
    loadRendiciones(fecha);
  }, [fecha, tipoSucursal]);

  const handleTablaTipoChange = (tipo) => {
    setIsLoading(true);
    setTipoSucursal(tablaTipo.filter((x) => x.id === parseInt(tipo))[0]);
  };

  const cargarTablaTipo = async () => {
    const tipos = await ConsultasAPI.ListarObjetos(URL_TIPOSUCURSAL);
    const tiposTabla = tipos.data.results;
    tiposTabla.push({
      fecha_creacion: "21/12/2023 16:21:58",
      fecha_modificacion: "21/12/2023 16:22:07",
      id: 4,
      nombre: "TODOS"
    })
    if (AuthenticationHelper.getRol() === "SUCURSAL-CONCEPCION") {
      const sucursal_concepcion = tiposTabla.filter(tipo => tipo.id === 3);
      setTablaTipo(sucursal_concepcion);
      setTipoSucursal(sucursal_concepcion[0]);
    } else {
      setTablaTipo(tiposTabla);
    }
  };

  const loadRendiciones = (value) => {
    setIsLoading(true);
    setFecha(value);
    setFechaRendicion(moment(value).format('DD/MM/YYYY'));
    setFechaSorteo(moment(value).add(-2, 'days').format('DD/MM/YYYY'));

    var filters = { fecha: value, tipoSucursal: tipoSucursal.id };;

    ConsultasAPI.ListarObjetos(
      URL_RENDICION_AGENCIA + "ListaPorFecha/",
      null,
      null,
      filters
    )
      .then((response) => {
        const rendicionesRec = [];
        const rendicionesExp = [];
        const rendicionesVen = [];
        const rendicionesPen = [];
        response.data
          // .filter(rendicion => rendicion.agencia.numero_agencia!==0 && rendicion.agencia.numero_agencia!==99)
          .forEach((rendicion) => {
            rendicionesRec.push({
              id: rendicion.id,
              titular:
                rendicion.titular?.persona_detalle?.apellido +
                ", " +
                rendicion.titular?.persona_detalle?.nombre,
              telefono: rendicion.agencia.telefono,
              domicilio: rendicion.domicilio,
              nueva_agencia: rendicion.agencia.numero_agencia,
              numeroRendicion: rendicion.numero,
              fechaRendicion: rendicion.fecha_inicio,
              ingresoMatutino: Parser.currency(
                parseFloat(rendicion.ingresoMatutino) ?? 0
              ),
              egresoMatutino: Parser.currency(
                parseFloat(rendicion.egresoMatutino) ?? 0
              ),
              ingresoVespertino: Parser.currency(
                parseFloat(rendicion.ingresoVespertino) ?? 0
              ),
              egresoVespertino: Parser.currency(
                parseFloat(rendicion.egresoVespertino) ?? 0
              ),
              ingresoSiesta: Parser.currency(
                parseFloat(rendicion.ingresoSiesta) ?? 0
              ),
              egresoSiesta: Parser.currency(
                parseFloat(rendicion.egresoSiesta) ?? 0
              ),
              ingresoTarde: Parser.currency(
                parseFloat(rendicion.ingresoTarde) ?? 0
              ),
              egresoTarde: Parser.currency(
                parseFloat(rendicion.egresoTarde) ?? 0
              ),
              ingresoNocturno: Parser.currency(
                parseFloat(rendicion.ingresoNocturno) ?? 0
              ),
              egresoNocturno: Parser.currency(
                parseFloat(rendicion.egresoNocturno) ?? 0
              ),
              totalIngreso: Parser.currency(
                parseFloat(rendicion.ingreso_total) ?? 0
              ),
              totalEgreso: Parser.currency(
                parseFloat(rendicion.egreso_total) ?? 0
              ),
              totalARendir: Parser.currency(
                parseFloat(rendicion.total_a_rendir) ?? 0
              ),
            });

            if (rendicion.agencia.numero_agencia !== 0) {
              rendicionesExp.push({
                Agencia: rendicion.agencia.numero_agencia,
                "Ingreso Matutino": parseFloat(rendicion.ingresoMatutino ?? 0),
                "Ingreso Vespertino": parseFloat(rendicion.ingresoVespertino ?? 0),
                "Ingreso Siesta": parseFloat(rendicion.ingresoSiesta ?? 0),
                "Ingreso Tarde": parseFloat(rendicion.ingresoTarde ?? 0),
                "Ingreso Nocturno": parseFloat(rendicion.ingresoNocturno ?? 0),
                "Ingreso Total": parseFloat(rendicion.ingreso_total ?? 0),
                "Egreso Matutino": parseFloat(rendicion.egresoMatutino ?? 0),
                "Egreso Vespertino": parseFloat(rendicion.egresoVespertino ?? 0),
                "Egreso Siesta": parseFloat(rendicion.egresoSiesta ?? 0),
                "Egreso Tarde": parseFloat(rendicion.egresoTarde ?? 0),
                "Egreso Nocturno": parseFloat(rendicion.egresoNocturno ?? 0),
                "Egreso Total": parseFloat(rendicion.egreso_total ?? 0),
                "Total a Rendir": parseFloat(rendicion.total_a_rendir ?? 0),
              });
              if (rendicion.estado_rendicion === "Vencida") {
                rendicionesVen.push({
                  Agencia: rendicion.agencia.numero_agencia,
                  fecha_inicio: rendicion.fecha_inicio,
                  "Ingreso Matutino": parseFloat(rendicion.ingresoMatutino ?? 0),
                  "Ingreso Vespertino": parseFloat(rendicion.ingresoVespertino ?? 0),
                  "Ingreso Siesta": parseFloat(rendicion.ingresoSiesta ?? 0),
                  "Ingreso Tarde": parseFloat(rendicion.ingresoTarde ?? 0),
                  "Ingreso Nocturno": parseFloat(rendicion.ingresoNocturno ?? 0),
                  "Ingreso Total": parseFloat(rendicion.ingreso_total ?? 0),
                  "Egreso Matutino": parseFloat(rendicion.egresoMatutino ?? 0),
                  "Egreso Vespertino": parseFloat(rendicion.egresoVespertino ?? 0),
                  "Egreso Siesta": parseFloat(rendicion.egresoSiesta ?? 0),
                  "Egreso Tarde": parseFloat(rendicion.egresoTarde ?? 0),
                  "Egreso Nocturno": parseFloat(rendicion.egresoNocturno ?? 0),
                  "Egreso Total": parseFloat(rendicion.egreso_total ?? 0),
                  "Total a Rendir": parseFloat(rendicion.total_a_rendir ?? 0),
                });
              };
              if (rendicion.estado_rendicion === "Pendiente" && moment(rendicion.fecha_inicio,"DD/MM/YYYY").format("YYYY-MM-DD") !== value) {
                rendicionesPen.push({
                  Agencia: rendicion.agencia.numero_agencia,
                  fecha_inicio: rendicion.fecha_inicio,
                  "Ingreso Matutino": parseFloat(rendicion.ingresoMatutino ?? 0),
                  "Ingreso Vespertino": parseFloat(rendicion.ingresoVespertino ?? 0),
                  "Ingreso Siesta": parseFloat(rendicion.ingresoSiesta ?? 0),
                  "Ingreso Tarde": parseFloat(rendicion.ingresoTarde ?? 0),
                  "Ingreso Nocturno": parseFloat(rendicion.ingresoNocturno ?? 0),
                  "Ingreso Total": parseFloat(rendicion.ingreso_total ?? 0),
                  "Egreso Matutino": parseFloat(rendicion.egresoMatutino ?? 0),
                  "Egreso Vespertino": parseFloat(rendicion.egresoVespertino ?? 0),
                  "Egreso Siesta": parseFloat(rendicion.egresoSiesta ?? 0),
                  "Egreso Tarde": parseFloat(rendicion.egresoTarde ?? 0),
                  "Egreso Nocturno": parseFloat(rendicion.egresoNocturno ?? 0),
                  "Egreso Total": parseFloat(rendicion.egreso_total ?? 0),
                  "Total a Rendir": parseFloat(rendicion.total_a_rendir ?? 0),
                });
              };
            };
          });
        setRendicionesRecibosVen(rendicionesVen);
        setRendicionesExport(rendicionesExp);
        setRendicionesRecibos(rendicionesRec);
        setRendicionesRecibosPen(rendicionesPen);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const envioLineaCaja = async () => {
    setIsSending(true);
    for (let index = 0; index < rendicionesRecibos.length; index++) {
      const rendicion = rendicionesRecibos[index];
      let formData = new FormData();
      const blob = await ReciboRendicionesLinea({ datos: rendicion });
      formData.append('rendiciones', rendicion.id);
      formData.append('linea_caja', blob);
      ConsultasAPI.CrearObjetoArchivo(URL_RENDICION_AGENCIA + "enviarLineaCaja/", formData)
        .then((response) => console.info(response))
        .catch((error) => console.error(error));
    };
    setIsSending(false);
  };

  return isLoading && isOpen ? (
    <Modal centered show={isLoading} onHide={() => setIsLoading(false)}>
      <Modal.Body className="text-center">
        <Spinner animation="border" />
        <p>Cargando Datos...</p>
      </Modal.Body>
    </Modal>
  ) : (
    <>
      <Modal centered show={isOpen} size="lg">
        <Modal.Header closeButton onClick={props.onClose}>
          <Modal.Title>Ingrese Fecha de Rendicion</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center mt-2 mb-2">
          <Form.Group style={{ margin: 5 }}>
            <Form.Label>Sucursal de Rendición</Form.Label>
            <Form.Control
              as="select"
              required
              value={tipoSucursal.id}
              onChange={(event) => {
                handleTablaTipoChange(event.target.value);
                // loadRendiciones(fecha)
              }}
            >
              <option value="" hidden>Elegir Tipo</option>
              {
                tablaTipo.length > 0 ?
                  tablaTipo.map((tipo) => (
                    <option key={tipo.id} value={tipo.id}>
                      {tipo.nombre}
                    </option>
                  ))
                  : null
              }
            </Form.Control>
          </Form.Group>
          <Form.Group style={{ margin: 5 }}>
            <Form.Label>Fecha Rendicion</Form.Label>
            <Datetime
              style={{ minWidth: "100%" }}
              closeOnSelect
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
              inputProps={{
                readOnly: true,
                placeholder: fecha,
              }}
              value={fecha}
              onChange={(momentDate) => {
                setFecha(momentDate.format("YYYY-MM-DD"));
              }}
            // onChange={loadRendiciones}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <section className="d-flex justify-content-end my-1">
            <Button
              className="btn botonCPA my-4"
              onClick={props.onClose}
              style={{ marginRight: 10, height: 40 }}
            >
              <FaStepBackward className="iconAntD" />
              Volver
            </Button>
            {
              tipoSucursal.id !== '' ?
                <>
                  <Button
                    className="btn botonCPA my-4"
                    onClick={generarRecibos}
                    disabled={rendicionesRecibos.length === 0}
                    style={{ marginRight: 10, height: 40, lineHeight: 1 }}
                  >
                    <AiFillCopy className="iconAntD" />
                    GENERAR RECIBOS
                  </Button>

                  <MovimientosDeOperaciones
                    datos={rendicionesExport}
                    datosVen={rendicionesRecibosVen}
                    datosPen={rendicionesRecibosPen}
                    fechaR={fechaRendicion}
                    fechaS={fechaSorteo}
                    style={{ height: 40, lineHeight: 1 }}
                    sucursal={tipoSucursal.nombre}
                  >
                    <AiOutlineDownload className="iconAntD" />
                    EXPORTAR MOVIMIENTO
                  </MovimientosDeOperaciones>
                  {
                    tipoSucursal.nombre === 'CASA CENTRAL' ?
                      <Button
                        className="btn botonCPA my-4"
                        onClick={envioLineaCaja}
                        disabled={rendicionesRecibos.length === 0}
                        style={{ marginLeft: 10, height: 40, lineHeight: 1 }}
                      >
                        <AiOutlineSend className="iconAntD" />
                        Enviar a Linea de Caja
                      </Button>
                      :
                      null
                  }
                </>
                :
                null
            }
          </section>
        </Modal.Footer>
      </Modal>
      <Modal centered show={isSending}>
        <Modal.Body className="text-center">
          <Spinner animation="border" />
          <p>Enviando Recibos a Linea de Caja...</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalLeerRendicion;
